import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CommonAlertsShow } from "../Common/Alerts";

const ConsoleProjectRoute = (props) => {
  const navigate = useNavigate();
  const routeParams = useParams()
  const [isValidProject, setIsValidProject] = useState(false);
  const checkProjectId = () => {
    if (routeParams.projectId === null || routeParams.projectId === '' || routeParams.projectId === 'undefined') {
        setIsValidProject(false);
        CommonAlertsShow({ Type: 'Info', Message: 'Select a Project to Continue', Title: 'Select Project' })
        return navigate('/console/projects');
      } else {
        setIsValidProject(true);
      }
  }
  
  useEffect(() => {
    checkProjectId();
  }, [isValidProject]);
  
  return (
    <React.Fragment>
      {
        isValidProject ? props.children : null
      }
    </React.Fragment>
  );
}
export default ConsoleProjectRoute;