import { footerData } from '../../lib/Constants/Index'
import { SaveLoginInfo } from '../RouteActions/AccountAuth'
import { useState } from 'react';
import { AccountSignInApi } from '../../lib/Apis/Accounts';
import { useNavigate } from 'react-router-dom';
import { HomeFooterComponent } from '../Home/Footer';
import { CommonAlertsShow } from '../Common/Alerts';
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
} from '@mantine/core'

export default function AccountsSignInComponent() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [serverError, setServerError] = useState('')
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const navigate = useNavigate()

  function handleEmailTypeEvent(e) {
    setEmail(e.target.value)
  }
  function handlePasswordTypeEvent(e) {
    setPassword(e.target.value)
  }
  function handleSignUp() {
    if (typeof window !== 'undefined') {
      navigate('/signup')
    }
  }
  function handleRecoveryRequest() {
    if (typeof window !== 'undefined') {
      navigate('/recovery')
    }
  }
  async function handleSignIn() {
    setLoadingOverlay(true)
    setEmailError('')
    setPasswordError('')
    const response = await AccountSignInApi({ email: email, password: password })
    setLoadingOverlay(false)
    if (response.status === 200) {
      SaveLoginInfo(response.json)
      navigate('/projects/' + response.json.project_id + '/console')
    } else if (response.status === 400) {
      if ('errors' in response.json) {
        if ('email' in response.json.errors) {
          setEmailError(response.json.errors.email)
        }
      }
      if ('errors' in response.json) {
        if ('password' in response.json.errors) {
          setPasswordError(response.json.errors.password)
        }
      }
    } else if (response.status === 500) {
      if ('error' in response.json) {
        setServerError(response.json.error)
        CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      } else {
        setServerError('Internal error')
        CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      }
    }
  }
  return (
    <>
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
        >
          Sign In
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Do not have an account yet?{' '}
          <Anchor size="sm" component="button" onClick={() => handleSignUp()}>
            Sign Up
          </Anchor>
        </Text>
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
          <TextInput label="Email" placeholder="name@email.com" required onChange={handleEmailTypeEvent} value={email} />
          <Text fz="sm" c="red">{emailError}</Text>
          <PasswordInput label="Password" placeholder="Your password" required mt="md" onChange={handlePasswordTypeEvent} value={password} />
          <Text fz="sm" c="red">{passwordError}</Text>
          <Text fz="sm" c="red">{serverError}</Text>
          <Button fullWidth mt="xl" onClick={() => handleSignIn()}>
            Sign In
          </Button>
          <Group position="apart" mt="lg">
            <Anchor size="sm" component="button" onClick={() => handleSignUp()}>
              Sign Up
            </Anchor>
            <Anchor component="button" size="sm" onClick={() => handleRecoveryRequest()}>
              Forgot Password?
            </Anchor>
          </Group>
        </Paper>
      </Container>
      <HomeFooterComponent
        data={footerData}
      />
    </>
  );
}
