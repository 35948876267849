import { Button, Center, Container, Group, Paper, Stack, Text } from "@mantine/core";
import { IconPackageExport, IconSoup, IconStack2 } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { SearchQueriesModalComponent } from "./SearchQueriesModal";
import ConsoleSourcesCreateModalComponent from "./SourcesCreateModal";
import { useState } from "react";
import ConsoleGroupsCreateModalComponent from "./GroupsCreateModal";

export function StartQueryPaperComponent({ BearerToken, projectId }) {
  const [searchModal, searchModalHandlers] = useDisclosure(false);
  return (
    <>
      {
        searchModal &&
        <SearchQueriesModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          searchModal={searchModal}
          close={searchModalHandlers.close}
        />
      }
      <PaperComponent
        titleText="Queries"
        detailText="Queries is where you can start asking QueryMyDoc about the Sources or Source Groups you have created."
        actionText="Start Query Session"
        actionFn={searchModalHandlers.open}
        icon="queries"
      />
    </>
  )
}

export function CreateSourceComponent({ BearerToken, projectId }) {
  const [createModal, createModalHandlers] = useDisclosure(false)
  const [version, setVersion] = useState(1)

  return (
    <>
      {
        createModal &&
        <ConsoleSourcesCreateModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          createModal={createModal}
          close={createModalHandlers.close}
          setVersion={setVersion}
        />
      }
      <PaperComponent
        titleText="Sources"
        detailText="Sources is where you can upload multiple files or specify links to website. After creating a Source you can immediately start querying on the Source."
        actionText="Create Source"
        actionFn={createModalHandlers.open}
        icon="sources"
      />
    </>
  )
}

export function CreateGroupComponent({ BearerToken, projectId }) {
  const [createModal, createModalHandlers] = useDisclosure(false)
  const [version, setVersion] = useState(1)
  return (
    <>
      {
        createModal &&
        <ConsoleGroupsCreateModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          createModal={createModal}
          close={createModalHandlers.close}
          setVersion={setVersion}
        />
      }
      <PaperComponent
        titleText="Groups"
        detailText="Groups is where you can club multiple Sources into a Group. This group can then be using for Querying."
        actionText="Create Group"
        actionFn={createModalHandlers.open}
        icon="groups"
      />
    </>
  )
}

export function PaperComponent({ icon, titleText, detailText, actionText, actionFn }) {
  function GetIcon() {
    if (icon === 'queries') {
      return (
        <IconSoup
          size={60}
          strokeWidth={1.5}
          color={'#228BE6'}
        />
      )
    } else if (icon === 'sources') {
      return (
        <IconPackageExport
          size={60}
          strokeWidth={1.5}
          color={'#228BE6'}
        />
      )
    } else if (icon === 'groups') {
      return (
        <IconStack2
          size={60}
          strokeWidth={1.5}
          color={'#228BE6'}
        />
      )
    }
    return (
      <></>
    )
  }

  return (
    <Center>
    <Paper shadow="sm" radius="md" p="sm">
      <Group position="apart">
        <Stack><GetIcon></GetIcon>
          <Text weight={500}>{titleText}</Text></Stack>
        <Container size="xs" px="xs"><Text size="sm" color="dimmed">
          {detailText}
        </Text></Container>
      </Group>
      <Button variant="light" color="blue" fullWidth mt="md" radius="md" onClick={actionFn}>
        {actionText}
      </Button>
    </Paper>
    </Center>
  )
}