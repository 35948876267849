
export default function ApiBaseUrl() {
  let baseUrl = 'http://192.168.64.13:8001'
  if (typeof window !== 'undefined') {
    let origin = window.location.origin
    if (origin.includes('localhost') || origin.includes('0.0.0.0') || origin.includes('192.168')) {
      baseUrl = 'http://192.168.64.13:8001'
    } else if (origin.includes('https://staging-react')) {
      baseUrl = 'https://staging-flask.querymydoc.com'
    } else if (origin.includes('https://querymydoc.com')) {
      baseUrl = 'https://api.querymydoc.com'
    }
  }
  return baseUrl
}