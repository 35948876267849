import { Container, Group, LoadingOverlay, Modal, Paper, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { GetGroupV1Api } from "../../lib/Apis/Groups";
import { IconStack2 } from "@tabler/icons-react";

export default function ConsoleGroupsViewModalComponent({ BearerToken, projectId, viewModal, close, rowId }) {
  const [loadingOverlay, setLoadingOverlay] = useState(true)
  const [groupName, setGroupName] = useState('Group')
  const [sources, setSources] = useState([])

  useEffect(() => {
    try {
      if (rowId !== null && rowId !== '') {
        setLoadingOverlay(true)
        const response = GetGroupV1Api({ BearerToken: BearerToken, projectId: projectId, Id: rowId })
        response.then((result) => {
          if (result.status === 200) {
            setGroupName(result.json.name)
            setSources(result.json.sources)
          } else if (result.status === 500) {
            console.log('ConsoleGroupsViewModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleGroupsViewModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoadingOverlay(false)
        })
      }
    } catch (error) {
      console.log('ConsoleGroupsViewModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [rowId])

  return (
    <Modal opened={viewModal} onClose={close} size="lg">
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <Paper shadow="sm" radius="md" p="sm">
        <Group position="apart">
          <IconStack2
            size={60}
            strokeWidth={1.5}
            color={'#228BE6'}
          />
          <Text fw={500} c="gray">{groupName}</Text>
          <Container size="xs" px="xs"><Text size="sm" color="dimmed">
            <Text fw={500} c="gray">Sources</Text>
            {sources.map(
              (source, index) => (
                <Text key={index} c="grey" fz="sm" fw={300}>Source #{index + 1} Name: {source.name}</Text>
              )
            )}
          </Text></Container>
        </Group>
      </Paper>
    </Modal>
  )
}