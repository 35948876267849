import { ScrollArea, Table, TextInput, Text, ActionIcon, Group, UnstyledButton, Center, Button, Divider, LoadingOverlay, NativeSelect, Space, ThemeIcon, Tooltip } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { IconCaretDown, IconCaretLeft, IconCaretRight, IconCaretUp, IconCheck, IconEdit, IconEye, IconTrash, IconX } from "@tabler/icons-react";

export function TablesCustomComponent({ GetRowsFn, DeleteRowFn, ViewRowFn, EditRowFn, BearerToken, projectId = null, Columns, version, setSelectedRow, PrimaryRowActions }) {
  const [tableRows, setTableRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortColumn, setSortColumn] = useState(Columns[0].apiKey);
  const [sortOrder, setSortOrder] = useState(1);
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [page, setPage] = useState(1);
  const pages = useRef(0);
  const total = useRef(0);
  const [count, setCount] = useState(10);

  useEffect(() => {
    handleFetchRows({ SortColumn: sortColumn, SortOrder: sortOrder, SearchTerm: searchTerm, Page: page, Count: count })
  }, [version]);

  async function handleFetchRows({ SortColumn, SortOrder, SearchTerm, Page, Count }) {
    try {
      setLoadingOverlay(true)
      let response = GetRowsFn({ BearerToken: BearerToken, projectId: projectId, SortColumn: SortColumn, SortOrder: SortOrder, SearchTerm: SearchTerm, Page: Page, Count: Count })
      response.then((result) => {
        if (result.status === 200) {
          let newTableRows = []
          result.json.data.forEach(
            (i) => {
              let newTableRow = {}
              Columns.map(
                (column) => (newTableRow[column.id] = typeof i[column.apiKey] === 'undefined' ? '' : i[column.apiKey])
              )
              newTableRow['id'] = i['_id']
              newTableRows.push(newTableRow)
            }
          )
          setSortColumn(result.json.params.sort_column)
          setSortOrder(result.json.params.sort_order)
          setSearchTerm(result.json.params.search_term)
          setPage(result.json.params.page)
          setCount(result.json.params.count)
          setTableRows(newTableRows)
          pages.current = result.json.pages
          total.current = result.json.total
        } else if (result.status === 500) {
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      setLoadingOverlay(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }

  function handleView(e, rowId) {
    e.preventDefault()
    setSelectedRow(rowId)
    ViewRowFn()
  }
  function handleEdit(e, rowId) {
    e.preventDefault()
    setSelectedRow(rowId)
    EditRowFn()
  }
  function handleDelete(e, rowId) {
    e.preventDefault()
    try {
      let response = DeleteRowFn({ BearerToken: BearerToken, projectId: projectId, Id: rowId })
      response.then((result) => {
        if (result.status === 200) {
          handleFetchRows({ SortColumn: sortColumn, SortOrder: sortOrder, SearchTerm: searchTerm, Page: page, Count: count })
          CommonAlertsShow({ Type: 'Info', Message: 'Deleted', Title: 'Success' })
        } else if (result.status === 500) {
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: '+result.json.error, Title: 'Error' })
        }
      })
    } catch (error) {
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }

  function GetNoDataRow() {
    return (
      <tr key={"EmptyRow"}>
        <td key={"EmptyColumn"} colSpan={Columns.length + 1} align='center'>
          <Text fz="sm" c="gray" fw={500}>No Data</Text>
        </td>
      </tr>
    )
  }

  function getTableRow(Row, Columns) {
    return (
      <tr key={Row.id}>
        {Columns.map(
          (Column, index) => (
            <td key={Row.id + ':' + index}><Text c="grey" fw={400} fz="sm">
              {
                Column.type === 'string' ?
                  Row[`${Column.id}`] :
                  Column.type === 'objectArray' ?
                    Row[`${Column.id}`].map((subRow) => `[${subRow.name}]`) :
                    Column.type === 'bool' &&
                    (Row[`${Column.id}`] === true ?
                      <IconCheck size="1rem" color="grey" /> :
                      <IconX size="1rem" color="grey" />)
              }</Text></td>
          )
        )}
        <td key={Row.id + ':actions'}>
          <Group>
            {PrimaryRowActions.map(
              (action, index) => (
                <ActionIcon key={'PrimaryRowActions' + index} variant="transparent" onClick={(e) => { action.HandleFunction(e, Row) }} title={action.Title}>
                  {action.Icon}
                </ActionIcon>
              )
            )}
            <ActionIcon variant="transparent" onClick={(e) => handleView(e, Row.id)} title="View">
              <IconEye size="1rem" />
            </ActionIcon>
            <ActionIcon variant="transparent" onClick={(e) => handleEdit(e, Row.id)} title="Edit">
              <IconEdit size="1rem" />
            </ActionIcon>
            <ActionIcon variant="transparent" onClick={(e) => handleDelete(e, Row.id)} title="Delete">
              <IconTrash size="1rem" />
            </ActionIcon>
          </Group>
        </td>
      </tr>
    )
  }

  function GetTableColumn(Column, sortColumn, sortOrder) {
    return (
      <th key={Column.id}>

        {
          Column.apiKey === sortColumn ?
            (<UnstyledButton onClick={(e) => handleFetchRows({ SortColumn: Column.apiKey, SortOrder: sortOrder * -1, SearchTerm: searchTerm, Page: page, Count: count })}>
              <Group>
                <Text fw={500} fz="sm" c="grey">
                  {Column.label}
                </Text>
                <Center>
                  <Tooltip label={sortOrder === -1 ? "Sort Ascending" : "Sort Descending"}>
                    <ThemeIcon variant="light" color="gray">
                      {sortOrder === -1 ? <IconCaretDown size="0.9rem" /> : <IconCaretUp size="0.9rem" />}
                    </ThemeIcon>
                  </Tooltip>
                </Center>
              </Group>
            </UnstyledButton>)
            :
            (
              <UnstyledButton onClick={(e) => handleFetchRows({ SortColumn: Column.apiKey, SortOrder: sortOrder * -1, SearchTerm: searchTerm, Page: page, Count: count })}>
                <Group>
                  <Tooltip label="Sort">
                    <Text fw={500} fz="sm" c="grey">
                      {Column.label}
                    </Text>
                  </Tooltip>
                </Group>
              </UnstyledButton>
            )
        }
      </th>
    )
  }
  return (
    <ScrollArea>
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <Group position="right" spacing="xs">
        <TextInput placeholder="Search" size="xs" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}></TextInput>
        <Button size="xs" variant="light" onClick={() => handleFetchRows({ SortColumn: sortColumn, SortOrder: sortOrder, SearchTerm: searchTerm, Page: 1, Count: count })}>Search</Button>
      </Group>
      <Space h="xs" />
      <Table highlightOnHover>
        <thead>
          <tr>
            {
              Columns.map((Column) => GetTableColumn(Column, sortColumn, sortOrder))
            }
            <th key={"Actions"}><Text fz="sm" c="gray" fw={600}>Actions</Text></th>
          </tr>
        </thead>
        <tbody>
          {
            tableRows.length > 0 ? tableRows.map((tableRow) => getTableRow(tableRow, Columns)) : GetNoDataRow()
          }
        </tbody>
      </Table>
      <Divider />
      <Space h="xs" />
      <Group position="right" spacing="xs">
        <Text fw={500} fz="sm" c="gray">Rows per page:</Text>
        <NativeSelect size="xs" data={['10', '20', '30', '50']} value={count.toString()} onChange={(e) => handleFetchRows({ SortColumn: sortColumn, SortOrder: sortOrder, SearchTerm: searchTerm, Page: 1, Count: e.target.value })} />
        <Text fz="sm" c="gray">{(page - 1) * count + 1} - {tableRows.length === 0 ? 0 : (page - 1) * count + tableRows.length} of {total.current}</Text>
        <Button size="xs" variant="light" disabled={page === 1 ? true : false} onClick={() => handleFetchRows({ SortColumn: sortColumn, SortOrder: sortOrder, SearchTerm: searchTerm, Page: page - 1, Count: count })}><IconCaretLeft size="0.9rem" stroke={1.5} /></Button>
        <Button size="xs" variant="light" disabled={pages.current <= page ? true : false} onClick={() => handleFetchRows({ SortColumn: sortColumn, SortOrder: sortOrder, SearchTerm: searchTerm, Page: page + 1, Count: count })}><IconCaretRight size="0.9rem" stroke={1.5} /></Button>
      </Group>
    </ScrollArea>
  );
}