
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetAccountInfoApi } from "../../lib/Apis/Accounts";
const ConsoleProtectedRoute = (props) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const checkUserToken = () => {
    const response = GetAccountInfoApi({ BearerToken: window.localStorage.getItem('BearerToken') })
    response.then((result) => {
      if (result.status !== 200) {
        setIsLoggedIn(false);
        return navigate('/signin');
      } else {
        setIsLoggedIn(true);
      }
    })
  }
  useEffect(() => {
    checkUserToken();
  }, [isLoggedIn]);
  return (
    <React.Fragment>
      {
        isLoggedIn ? props.children : null
      }
    </React.Fragment>
  );
}
export default ConsoleProtectedRoute;