import ApiBaseUrl from "./Defaults"

export async function GetGroupsV1Api({ projectId, BearerToken, SortColumn, SortOrder, SearchTerm, Page, Count }) {
  let queryParams = {
    'sort_column': SortColumn,
    'sort_order': SortOrder,
    'search_term': SearchTerm,
    'page': Page,
    'count': Count
  }
  let urlQueryParams = new URLSearchParams(queryParams).toString()
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/groups?' + urlQueryParams
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetGroupsV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function DeleteGroupV1Api({ projectId, BearerToken, Id }) {
  let requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/groups/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('DeleteGroupV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function GetGroupV1Api({ projectId, BearerToken, Id }) {
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/groups/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetGroupV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function UpdateGroupV1Api({ projectId, BearerToken, formValues, Id=null }) {
  try {
    let jsonData = {}
    jsonData['name'] = formValues['name']
    jsonData['sources'] = formValues['sources']
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + BearerToken,
      },
      body: JSON.stringify(jsonData)
    }
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/groups'
    if (Id !== null){
      Url += '/' + Id
      requestOptions.method = 'PUT'
    }
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('UpdateGroupV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}