import {
  Text,
} from '@mantine/core';
import { useParams } from 'react-router-dom';

export default function ConsoleHelpSectionComponent({ selectBar = '' }) {
  const routeParams = useParams()
  if (selectBar === '') {
    return (
      <>
        <Text c="grey" fw={600} fz="sm">How to use this Console?</Text>
      </>
    )
  } else if (selectBar === 'queries') {
    return (
      <>
        <Text c="grey" fw={600} fz="sm">How to use the Queries?</Text>
        <Text c="grey" fw={300} fz="sm">Queries is where you can start asking QueryMyDoc about the sources you have created. Eg. "What are the findings from the research?"</Text>
      </>
    )
  } else if (selectBar === 'sources') {
    return (
      <>
        <Text c="grey" fw={600} fz="sm">How to use the Sources?</Text>
        <Text c="grey" fw={300} fz="sm">Sources is where you can upload a file (pdf/text) or specify link to a website or link to download files.</Text>
      </>
    )
  } else if (selectBar === 'groups') {
    return (
      <>
        <Text c="grey" fw={600} fz="sm">How to use the Groups?</Text>
        <Text c="grey" fw={300} fz="sm">Groups is where you can club your sources together. Queries can be then run on a Group of Sources.</Text>
      </>
    )
  } else if (selectBar === 'settings') {
    return (
      <>
        <Text c="grey" fw={600} fz="sm">How to use the Settings?</Text>
        {
          routeParams.tabValue === null &&
          <Text c="grey" fw={300} fz="sm">Setting is where you can manage Projects, Account, Billing etc.</Text>
        }
        {
          routeParams.tabValue === "projects" &&
          <Text c="grey" fw={300} fz="sm">Project settings is where you can manage your Projects. Project is a like a container which holds together all your Sources and Queries. So one Project cannot access another Project. For example you can create a Project called "Personal Project" for holding all your personal data and another Project called "Professional Project" for holding all your professional data.</Text>
        }
        {
          routeParams.tabValue === "account" &&
          <Text c="grey" fw={300} fz="sm">Account setting is where you can verify your email, change email, add a name to your account, change password, delete your account.</Text>
        }
        {
          routeParams.tabValue === "billing" &&
          <Text c="grey" fw={300} fz="sm">Billing setting is where you can subscribe to a paid plan and unlock premium features, have higher limit on sources and queries, dedicated support etc.</Text>
        }
      </>
    )
  }
}