import { ThemeIcon, UnstyledButton, Group, Text, useMantineTheme, Box, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
  IconLogout,
  IconSwitchHorizontal,
  IconSettings,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { SearchProjectsModalComponent } from './SearchProjectsModal';

const data = [
  { icon: <IconSwitchHorizontal size="1rem" />, label: 'Switch Project', task: 'projects' },
  { icon: <IconSettings size="1rem" />, label: 'Settings', task: 'settings' },
  { icon: <IconLogout size="1rem" />, label: 'Sign Out', task: 'signOut' },
];

export function ConsoleSettingsAndAccountLinksComponent({ selectBar = '', BearerToken }) {
  const theme = useMantineTheme();
  const [searchModal, searchModalHandlers] = useDisclosure(false);
  const links = data.map((link) => <SettingsAndAccountLinksButton icon={link.icon} task={link.task} label={link.label} selectBar={selectBar} key={link.label} />);
  const navigate = useNavigate()

  function handleLinkClick(task) {
    if (task === 'signOut') {
      if (typeof window !== 'undefined') {
        window.localStorage.removeItem('BearerToken')
      }
      navigate('/signin')
    } else if (task === 'projects') {
      searchModalHandlers.open()
    } else if (task === 'settings') {
      if (typeof window !== 'undefined') {
        navigate('/console/settings/projects')
      }
    }
  }

  function SettingsAndAccountLinksButton({ icon, label, task, selectBar }) {
    return (
      <UnstyledButton
        onClick={() => handleLinkClick(task)}
        sx={(theme) => ({
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
          backgroundColor: selectBar === task ? theme.colors.gray[1] : theme.white,
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
          },
        })}
      >
        <Group>
          <ThemeIcon color="gray" variant="light">
            {icon}
          </ThemeIcon>
          <Text fz="sm" c="grey" fw={600}>{label}</Text>
        </Group>
      </UnstyledButton>
    );
  }

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `${rem(1)} solid ${theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
          }`,
      }}
    >
      {
        searchModal &&
        <SearchProjectsModalComponent
          BearerToken={BearerToken}
          searchModal={searchModal}
          close={searchModalHandlers.close}
        />
      }
      <div>{links}</div>
    </Box>
  );
}