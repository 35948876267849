import { notifications } from '@mantine/notifications';
import { generateRandomString } from '../../lib/Constants/Generic';

export function CommonAlertsShow({Id = generateRandomString(), Title, Message, Type = 'Error'}) {
  let backgroundColor = 'red'
  if ((typeof Type === 'undefined')||(Type === 'Error')){
    backgroundColor = 'red'
  } else if (Type === 'Info'){
    backgroundColor = 'blue'
  } else if (Type === 'Success'){
    backgroundColor = 'teal'
  }
  notifications.show({
    id: Id,
    withCloseButton: true,
    autoClose: Type === 'Error' ? 5000*4 : 5000,
    title: Title,
    message: Message,
    color: backgroundColor,
    loading: false,
  });
  return Id
}