import ApiBaseUrl from "./Defaults"

export async function GetProjectsV1Api({ BearerToken, SortColumn, SortOrder, SearchTerm, Page, Count }) {
  let queryParams = {
    'sort_column': SortColumn,
    'sort_order': SortOrder,
    'search_term': SearchTerm,
    'page': Page,
    'count': Count
  }
  let urlQueryParams = new URLSearchParams(queryParams).toString()
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects?' + urlQueryParams
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetProjectsV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function DeleteProjectV1Api({ BearerToken, Id }) {
  let requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('DeleteProjectV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function GetProjectV1Api({ BearerToken, Id }) {
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetProjectV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function UpdateProjectV1Api({ BearerToken, formValues, Id=null }) {
  try {
    let jsonData = {}
    jsonData['name'] = formValues['name']
    jsonData['timezone'] = formValues['timezone']
    jsonData['default'] = formValues['default']
    jsonData['openai_api_key'] = formValues['openAiApiKey']
    jsonData['openai_api_key_override'] = formValues['openAiApiKeyOverride']
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + BearerToken,
      },
      body: JSON.stringify(jsonData)
    }
    let Url = ApiBaseUrl() + '/v1/projects'
    if (Id !== null){
      Url += '/' + Id
      requestOptions.method = 'PUT'
    }
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('UpdateProjectV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}