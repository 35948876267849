import { Container, Divider, Paper, Space, Text } from "@mantine/core";
import TablesSimpleComponent from "../Tables/Simple";
import { IconSoup, IconTrash } from "@tabler/icons-react";
import { StartQueryPaperComponent } from "./Papers";
import { DeleteSessionV1Api, GetRecentQuerySessionsV1Api } from "../../lib/Apis/Queries";
import { CommonAlertsShow } from "../Common/Alerts";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function ConsoleQueriesIndexComponent({ BearerToken, projectId }) {
  const [version, setVersion] = useState(1)
  const navigate = useNavigate()
  function handleDeleteSession(e, Row) {
    try {
      let response = DeleteSessionV1Api({ projectId: projectId, BearerToken: BearerToken, Id: Row.id.split(':')[2] })
      response.then((result) => {
        if (result.status === 200) {
          setVersion(n => n + 1)
          CommonAlertsShow({ Type: 'Info', Message: 'Deleted', Title: 'Success' })
        } else if (result.status === 500) {
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
        }
      })
    } catch (error) {
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  function handleRunQuery(e, Row) {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + projectId + '/console/queries/' + Row.id.split(':')[0] + 's/' + Row.id.split(':')[1] + '/run?session=' + Row.id.split(':')[2] )
    }
  }
  return (
    <>
      <StartQueryPaperComponent
        BearerToken={BearerToken}
        projectId={projectId}
      />
      <Container size="md" px="xs">
        <Space h="xl" />
        <Space h="xl" />
        <Text fz="sm" fw={500} c="grey">Recent Query Sessions</Text>
        <Divider />
        <Space h="xs" />
        <Paper shadow="sm" radius="md" p="sm">
          <TablesSimpleComponent
            BearerToken={BearerToken}
            projectId={projectId}
            version={version}
            Columns={[
              { id: 'sourceGroup', type: 'string', label: 'Source/Group', apiKey: 'source_group' },
              { id: 'queryCount', type: 'string', label: 'Query Count', apiKey: 'query_count' },
              { id: 'queryPreview', type: 'string', label: 'Session Preview', apiKey: 'session_preview' },
              { id: 'sessionLastActiveTime', type: 'string', label: 'Session Last Active Time', apiKey: 'session_last_active_time' },
            ]}
            GetRowsFn={GetRecentQuerySessionsV1Api}
            PrimaryRowActions={[
              { Title: 'Resume Session', Icon: <IconSoup size="1rem" color="black" />, HandleFunction: handleRunQuery },
              { Title: 'Delete Session', Icon: <IconTrash size="1rem" color="gray" />, HandleFunction: handleDeleteSession }
            ]}
          />
        </Paper>
      </Container>
    </>
  )
}