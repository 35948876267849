import { Flex } from "@mantine/core"
import ConsoleSourcesIndexComponent from "./SourcesIndex"
import { useParams } from "react-router-dom"
import ConsoleQueriesRunComponent from "./QueriesRun"
import { CreateSourceComponent, CreateGroupComponent, StartQueryPaperComponent } from "./Papers"
import ConsoleGroupsIndexComponent from "./GroupsIndex"
import ConsoleSettingsIndexComponent from "./SettingsIndex"
import ConsoleProjectsSelectorComponent from "./ProjectsSelector"
import { GetAccountInfoApi } from "../../lib/Apis/Accounts"
import { CommonAlertsShow } from "../Common/Alerts"
import { useNavigate } from "react-router-dom";
import ConsoleQueriesIndexComponent from "./QueriesIndex"

export default function ConsoleMainContentComponent({ selectBar = '', action = '' }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const checkUserToken = () => {
    const response = GetAccountInfoApi({ BearerToken: window.localStorage.getItem('BearerToken') })
    response.then((result) => {
      if (result.status !== 200) {
        CommonAlertsShow({ Type: 'Error', Message: 'Session Expired. SignIn to Continue', Title: 'Signin Required' })
        return navigate('/signin');
      }
    })
  }
  checkUserToken()
  if (selectBar === '') {
    return (
      <Flex
        gap="xl"
        justify="center"
        align="center"
        direction="column"
        wrap="wrap"
      >
        <StartQueryPaperComponent
          BearerToken={window.localStorage.getItem('BearerToken')}
          projectId={routeParams.projectId}
        />
        <CreateSourceComponent
          BearerToken={window.localStorage.getItem('BearerToken')}
          projectId={routeParams.projectId}
        />
        <CreateGroupComponent
          BearerToken={window.localStorage.getItem('BearerToken')}
          projectId={routeParams.projectId}
        />
      </Flex>
    )
  } else if (selectBar === 'queries') {
    if (action === 'run') {
      return (
        <ConsoleQueriesRunComponent
          BearerToken={window.localStorage.getItem('BearerToken')}
          projectId={routeParams.projectId}
          sourceId={routeParams.sourceId}
          groupId={routeParams.groupId}
        />
      )
    } else {
      return (
        <ConsoleQueriesIndexComponent
          BearerToken={window.localStorage.getItem('BearerToken')}
          projectId={routeParams.projectId}
        />
      )
    }
  } else if (selectBar === 'sources') {
    return (
      <ConsoleSourcesIndexComponent
        BearerToken={window.localStorage.getItem('BearerToken')}
        projectId={routeParams.projectId}
      />
    )
  } else if (selectBar === 'groups') {
    return (
      <ConsoleGroupsIndexComponent
        BearerToken={window.localStorage.getItem('BearerToken')}
        projectId={routeParams.projectId}
      />
    )
  } else if (selectBar === 'settings') {
    return (
      <ConsoleSettingsIndexComponent
        BearerToken={window.localStorage.getItem('BearerToken')}
      />
    )
  } else if (selectBar === 'projects') {
    return (
      <ConsoleProjectsSelectorComponent
        BearerToken={window.localStorage.getItem('BearerToken')}
      />
    )
  }
}