import { Button, Divider, Group, Loader, NavLink, Paper, Space, TextInput, Title, Text, Modal } from "@mantine/core";
import { IconSearch, IconSettings2 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { useNavigate } from "react-router-dom";
import { GetProjectsV1Api } from "../../lib/Apis/Projects";
import { useDisclosure } from "@mantine/hooks";

export default function ConsoleProjectsSelectorComponent({ BearerToken }) {
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    handleQuery('')
  }, [])
  function handleQuery(query) {
    try {
      setLoading(true)
      let Count = 0
      let response = GetProjectsV1Api({ BearerToken: BearerToken, SortColumn: 'created_at', SortOrder: -1, SearchTerm: query, Page: 1, Count: Count })
      response.then(
        (result) => {
          if (result.status === 200) {
            let results = []
            result.json.data.forEach(
              (i) => {
                let iResult = { id: i._id, name: i.name }
                results.push(iResult)
              }
            )
            setSearchResults(results)
          }
          else {
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          }
          setLoading(false)
        }
      )
    } catch (error) {
      setLoading(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  function handleNavigate(e) {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + e.id + '/console')
      CommonAlertsShow({ Type: 'Success', Message: 'Current Project: ' + e.name, Title: 'Switched Project' })
    }
  }

  function handleProjects() {
    if (typeof window !== 'undefined') {
      navigate('/console/settings/projects')
    }
  }
  const [opened, { open, close }] = useDisclosure(true);
  return (
    <Modal opened={opened} onClose={close} size="md" closeOnEscape={false} closeOnClickOutside={false} withCloseButton={false} trapFocus={true}>
      <Group position="apart">
        <Title order={4} c="grey">Select a Project</Title>
        <Button variant="light" onClick={() => handleProjects()}>Manage Projects</Button>
      </Group>
      <Space h="sm" />
      <Divider />
      <Space h="sm" />
      <Text fz="sm" c="gray" fw={400}>You need to select a Project to Continue</Text>
      <Space h="sm" />
      <Paper shadow="sm" radius="md" p="sm">
        <TextInput data-autofocus icon={<IconSearch size="0.8rem" />} onChange={(e) => handleQuery(e.target.value)} placeholder="Search for Projects" rightSection={loading && <Loader size="xs" />}></TextInput>
        {searchResults.map(
          (result) => (
            <NavLink
              key={result.id}
              label={result.name}
              icon={<IconSettings2 size="1rem" stroke={1.5} />}
              variant="subtle"
              active
              onClick={() => handleNavigate(result)}
            />
          )
        )}
      </Paper>
    </Modal>
  )
}