import { ThemeIcon, UnstyledButton, Group, Text } from '@mantine/core';

import {
  IconStack2,
  IconPackageExport,
  IconSoup,
} from '@tabler/icons-react';
import { useNavigate, useParams } from 'react-router-dom';


function MainLinksButton({ icon, label, path, task, selectBar }) {
  const navigate = useNavigate()
  const routeParams = useParams()

  function handleLinkClick() {
    if (['queries', 'sources', 'groups'].includes(task)) {
      if (typeof window !== 'undefined') {
        navigate('/projects/' + routeParams.projectId + path)
      }
    }
  }

  return (
    <UnstyledButton
      onClick={() => handleLinkClick()}
      sx={(theme) => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        backgroundColor: selectBar === task ? theme.colors.gray[1] : theme.white,
        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },
      })}
    >
      <Group >
        <ThemeIcon variant="light">
          {icon}
        </ThemeIcon>
        <Text fz="sm" c="grey" fw={600}>{label}</Text>
      </Group>
    </UnstyledButton>
  );
}


const data = [
  { icon: <IconSoup size="1rem" />, label: 'Queries', task: 'queries', path: '/console/queries'},
  { icon: <IconPackageExport size="1rem" />, label: 'Sources', task: 'sources', path: '/console/sources'},
  { icon: <IconStack2 size="1rem" />, label: 'Groups', task: 'groups', path: '/console/groups'},
];

export function ConsoleMainLinksComponent({ selectBar = '' }) {
  const links = data.map((link) => <MainLinksButton icon={link.icon} path={link.path} task={link.task} label={link.label} selectBar={selectBar} key={link.label} />);
  return <div>{links}</div>;
}