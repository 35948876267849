import { Button, Group, LoadingOverlay, Modal, MultiSelect, NavLink, Space, Text, TextInput } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { GetGroupV1Api, UpdateGroupV1Api } from "../../lib/Apis/Groups";

export default function ConsoleGroupsUpdateModalComponent({ BearerToken, projectId, editModal, close, setVersion, rowId }) {
  const [loadingOverlay, setLoadingOverlay] = useState(true)
  const [sources, setSources] = useState([])

  const form = useForm({
    initialValues: {
      name: '',
      sources: [],
    },

    validate: {
      name: isNotEmpty(),
      sources: isNotEmpty(),
    },
  });

  useEffect(() => {
    try {
      if (rowId !== null && rowId !== '') {
        setLoadingOverlay(true)
        const response = GetGroupV1Api({ BearerToken: BearerToken, projectId: projectId, Id: rowId })
        response.then((result) => {
          if (result.status === 200) {
            let results = []
            result.json.all_sources.map(
              (i) => (results.push({ value: i._id, label: i.name }))
            )
            setSources(results)
            let groupSources = []
            result.json.sources.map(
              (i) => (groupSources.push(i._id))
            )
            form.setValues({ name: result.json.name, sources: groupSources })
          } else if (result.status === 500) {
            console.log('ConsoleGroupsUpdateModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleGroupsUpdateModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoadingOverlay(false)
        })
      }
    } catch (error) {
      console.log('ConsoleGroupsUpdateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [rowId])

  async function handleSubmit({ values }) {
    try {
      setLoadingOverlay(true)
      let response = UpdateGroupV1Api({ formValues: values, projectId: projectId, BearerToken: BearerToken, Id: rowId })
      response.then((result) => {
        if (result.status === 200) {
          CommonAlertsShow({
            Type: 'Success',
            Message: <NavLink
              key={result.json._id}
              label={<Text c="gray" fz="sm" fw={300}>Click here to start Querying</Text>}
              component="a"
              href={"/projects/" + projectId + "/console/queries/groups/" + result.json._id + '/run'}
            />,
            Title: 'Group Updated'
          })
          setVersion(n => n + 1)
          close()
        } else if (result.status === 500) {
          console.log('ConsoleGroupsUpdateModalComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('ConsoleGroupsUpdateModalComponent error', result)
          if (result.json.errors !== null) {
            form.setErrors(result.json.errors)
          } else {
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      setLoadingOverlay(false)
      console.log('ConsoleGroupsUpdateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  return (
    <Modal opened={editModal} onClose={close} size="md" title={<Text fw={500} c="gray">Update Group</Text>}>
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <form onSubmit={form.onSubmit((values) => handleSubmit({ values: values }))}>
        <TextInput
          label={<Text fz="sm" c="grey">Group Name</Text>}
          description="Name of the Group. Eg: Collection of Research Documents"
          placeholder="Group Name"
          size="xs"
          {...form.getInputProps('name')}
        />
        <Space h="xl" />
        <MultiSelect
          data={sources}
          defaultValue={form.values['sources']}
          placeholder="Select Sources from the list"
          searchable
          size="xs"
          {...form.getInputProps('sources')}
        />
        <Space h="xl" />
        <Group position="right" mt="md">
          <Button type="submit" variant="light">Update</Button>
        </Group>
      </form>
    </Modal>
  )
}