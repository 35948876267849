import { footerData } from '../../lib/Constants/Index'
import {
  TextInput,
  PasswordInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
} from '@mantine/core';
import { SaveLoginInfo } from '../RouteActions/AccountAuth'
import { useState } from 'react';
import { AccountSignUpApi, CheckPassword } from '../../lib/Apis/Accounts';
import { useNavigate } from 'react-router-dom';
import { HomeFooterComponent } from '../Home/Footer';
import { CommonAlertsShow } from '../Common/Alerts';

export default function AccountsSignUpComponent() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [serverError, setServerError] = useState('');
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const navigate = useNavigate()

  function handleEmailTypeEvent(e) {
    setEmail(e.target.value)
  }
  function handlePasswordTypeEvent(e) {
    setPasswordError('')
    setPassword(e.target.value)
    let errors = CheckPassword({password: e.target.value})
    if (errors.length > 0) {
      setPasswordError(errors.join(', '))
    }
  }
  function handleSignIn() {
    if (typeof window !== 'undefined' && window.localStorage.getItem('BearerToken') !== null) {
      navigate('/console/projects')
    }
  }
  function handleRecoveryRequest() {
    if (typeof window !== 'undefined') {
      navigate('/recovery')
    }
  }
  async function handleSignUp() {
    setServerError('')
    let errors = CheckPassword({password: password})
    if (errors.length > 0) {
      setPasswordError(errors.join(', '))
      CommonAlertsShow({ Type: 'Error', Message: errors.join(', '), Title: 'Password Error' })
      return
    }
    setLoadingOverlay(true)
    setEmailError('')
    setPasswordError('')
    const response = await AccountSignUpApi({ email: email, password: password })
    setLoadingOverlay(false)
    if (response.status === 200) {
      SaveLoginInfo(response.json)
      navigate('/projects/' + response.json.project_id + '/console')
    } else if (response.status === 400) {
      if ('errors' in response.json) {
        if ('email' in response.json.errors) {
          setEmailError(response.json.errors.email)
        }
      }
      if ('errors' in response.json) {
        if ('password' in response.json.errors) {
          setPasswordError(response.json.errors.password)
        }
      }
    } else if (response.status === 500) {
      if ('error' in response.json) {
        setServerError(response.json.error)
      } else {
        setServerError('Internal error')
      }
    }
  }
  return (
    <>
      <Container size={420} my={40}>
      <Title
        align="center"
        sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
      >
        Sign Up
      </Title>
      <Text color="dimmed" size="sm" align="center" mt={5}>
        Already have an account?{' '}
        <Anchor size="sm" component="button" onClick={() => handleSignIn()}>
          Sign In
        </Anchor>
      </Text>
      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
        <TextInput label="Email" placeholder="name@email.com" required value={email} onChange={handleEmailTypeEvent} />
        <Text fz="sm" c="red">{emailError}</Text>
        <PasswordInput label="Password" placeholder="Your password" required mt="md" value={password} onChange={handlePasswordTypeEvent} />
        <Text fz="sm" c="red">{passwordError}</Text>
        <Text fz="sm" c="red">{serverError}</Text>
        <Button fullWidth mt="xl" onClick={() => handleSignUp()}>
          Sign Up
        </Button>
        <Group position="apart" mt="lg">
          <Anchor size="sm" component="button" onClick={() => handleSignIn()}>
            Sign In
          </Anchor>
          <Anchor component="button" size="sm" onClick={() => handleRecoveryRequest()}>
              Forgot Password?
            </Anchor>
        </Group>
      </Paper>
    </Container>
      <HomeFooterComponent
        data={footerData}
      />
    </>
  );
}
