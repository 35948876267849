import { Badge, Group, Loader, Modal, NavLink, Text, TextInput } from "@mantine/core";
import { IconSearch, IconSettings2 } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { useNavigate, useParams } from "react-router-dom";
import { GetProjectsV1Api } from "../../lib/Apis/Projects";
import { GetProjectV1Api } from "../../lib/Apis/Projects";

export function SearchProjectsModalComponent({ BearerToken, searchModal, close }) {
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState([])
  const routeParams = useParams()
  const [currentProject, setCurrentProject] = useState('')
  useEffect(() => {
    handleQuery('')
    if (routeParams.projectId !== 'undefined' && routeParams.projectId !== null && routeParams.projectId !== '') {
      let response = GetProjectV1Api({ BearerToken: BearerToken, Id: routeParams.projectId })
      response.then(
        (result) => {
          if (result.status === 200) {
            setCurrentProject(result.json.name)
          }
        }
      )
    }
  }, [])
  function handleQuery(query) {
    try {
      setLoading(true)
      let Count = 0
      if (query === null || query === '') {
        Count = 5
      }
      let response = GetProjectsV1Api({ BearerToken: BearerToken, SortColumn: 'created_at', SortOrder: -1, SearchTerm: query, Page: 1, Count: Count })
      response.then(
        (result) => {
          if (result.status === 200) {
            let results = []
            result.json.data.forEach(
              (i) => {
                let iResult = { id: i._id, name: i.name }
                results.push(iResult)
              }
            )
            setSearchResults(results)
          }
          else {
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          }
          setLoading(false)
        }
      )
    } catch (error) {
      setLoading(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  function handleNavigate(e) {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + e.id + '/console')
      close()
      CommonAlertsShow({ Type: 'Success', Message: 'Current Project: ' + e.name, Title: 'Switched Project' })
    }
  }

  const [loading, setLoading] = useState(false)
  return (
    <Modal opened={searchModal} onClose={close} size="md" title={currentProject === '' ? <Text fw={400} c="grey">Project Finder</Text> : <Group position="center"><Badge size="md" radius="sm">Current Project</Badge><Text fw={400} fz="xs" c="grey">{currentProject}</Text></Group>} withCloseButton={false}>
      <TextInput data-autofocus icon={<IconSearch size="0.8rem" />} onChange={(e) => handleQuery(e.target.value)} placeholder="Search for Projects" rightSection={loading && <Loader size="xs" />}></TextInput>
      {searchResults.map(
        (result) => (
          <NavLink
            key={result.id}
            label={result.name}
            icon={<IconSettings2 size="1rem" stroke={1.5} />}
            variant="subtle"
            active
            onClick={() => handleNavigate(result)}
          />
        )
      )}
    </Modal>
  )
}