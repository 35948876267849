import ApiBaseUrl from "./Defaults"

export async function PostQueryV1Api({ BearerToken, projectId, sourceId = null, groupId = null, Query, sessionID = null }) {
  let Url
  if (sourceId !== null) {
    Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/queries/sources/' + sourceId + '/run'
  } else if (groupId !== null) {
    Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/queries/groups/' + groupId + '/run'
  }
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
    body: JSON.stringify({
      query: Query,
      session_id: sessionID
    })
  }
  try {
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('PostQueryV1Api error', error)
    return {
      status: 500,
      json: {
        error: 'Server Error'
      }
    }
  }
}

export async function GetRecentQuerySessionsV1Api({ projectId, BearerToken }) {
  let queryParams = {}
  let urlQueryParams = new URLSearchParams(queryParams).toString()
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/queries/sessions/recent?' + urlQueryParams
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetRecentQuerySessionsV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function DeleteSessionV1Api({ projectId, BearerToken, Id }) {
  let requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/queries/sessions/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('DeleteSessionV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function GetQuerySessionV1Api({ projectId, BearerToken, Id }) {
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/queries/sessions/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('DeleteSessionV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}