import { Loader, Modal, NavLink, Text, TextInput } from "@mantine/core";
import { IconSearch, IconSoup, IconStack2 } from "@tabler/icons-react";
import { GetSourcesV1Api } from "../../lib/Apis/Sources";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { useNavigate } from "react-router-dom";
import { GetGroupsV1Api } from "../../lib/Apis/Groups";

export function SearchQueriesModalComponent({ BearerToken, projectId, searchModal, close }) {
  const navigate = useNavigate()
  const [searchResults, setSearchResults] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    handleQuery('')
  }, [])
  function handleQuery(query) {
    try {
      setLoading(true)
      let Count = 0
      if (query === null || query === ''){
        Count = 5
      }
      let response1 = GetSourcesV1Api({ projectId: projectId, BearerToken: BearerToken, SortColumn: 'created_at', SortOrder: -1, SearchTerm: query, Page: 1, Count: Count })
      let response2 = GetGroupsV1Api({ projectId: projectId, BearerToken: BearerToken, SortColumn: 'created_at', SortOrder: -1, SearchTerm: query, Page: 1, Count: Count })
      response1.then(
        (result) => {
          if (result.status === 200) {
            let results = []
            result.json.data.forEach(
              (i) => {
                let iResult = { id: '/sources/' + i._id + '/run', name: i.name, type: 'source' }
                results.push(iResult)
              }
            )
            response2.then(
              (result) => {
                if (result.status === 200) {
                  result.json.data.forEach(
                    (i) => {
                      let iResult = { id: '/groups/' + i._id + '/run', name: i.name, type: 'group' }
                      results.push(iResult)
                    }
                  )
                  setSearchResults(results)
                }
                else {
                  CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
                }
                setLoading(false)
              }
            )
          }
          else {
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          }
        }
      )
    } catch (error) {
      setLoading(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  function handleNavigate(e) {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + projectId + '/console/queries' + e.id)
      close()
    }
  }
  return (
    <Modal opened={searchModal} onClose={close} size="md" title={<Text fw={400} c="grey">Finder</Text>} withCloseButton={false}>
      <TextInput data-autofocus icon={<IconSearch size="0.8rem" />} onChange={(e) => handleQuery(e.target.value)} placeholder="Search for Sources or Groups" rightSection={loading && <Loader size="xs" />}></TextInput>
      {searchResults.map(
        (result) => (
          <NavLink
            key={result.id}
            label={result.name}
            icon={result.type === 'source' ? <IconSoup size="1rem" stroke={1.5} /> : <IconStack2 size="1rem" stroke={1.5} />}
            variant="subtle"
            active
            onClick={() => handleNavigate(result)}
          />
        )
      )}
    </Modal>
  )
}