import ApiBaseUrl from "./Defaults"

const passwordRequirements = [
  { re: /^.{8,}$/, label: 'Needs 8 characters' },
  { re: /[0-9]/, label: 'Needs number' },
  { re: /[a-z]/, label: 'Needs lowercase letter' },
  { re: /[A-Z]/, label: 'Needs uppercase letter' },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: 'Needs special symbol' },
];

export function CheckPassword({ password }) {
  let errors = []
  passwordRequirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      errors.push(requirement.label)
    }
  });
  return errors
}

export async function AccountSignInApi({ email, password }: { email: string, password: string }) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password
    })
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/signin'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('AccountSignInApi error', error)
    return {
      status: 500,
      json: {
        error: 'Server Error'
      }
    }
  }
}

export async function AccountSignUpApi({ email, password }: { email: string, password: string }) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
      password: password
    })
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/signup'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('AccountSignUpApi error', error)
    return {
      status: 500,
      json: {
        error: 'Server Error'
      }
    }
  }
}

export async function AccountRecoveryRequestApi({ email }) {
  let requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      email: email,
    })
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/recovery'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('AccountRecoveryRequestApi error', error)
    return {
      status: 500,
      json: {
        error: 'Server Error'
      }
    }
  }
}

export async function AccountRecoveryConfirmApi({ password, token }) {
  let requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      password: password,
      token: token
    })
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/recovery'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('AccountRecoveryConfirmApi error', error)
    return {
      status: 500,
      json: {
        error: 'Server Error'
      }
    }
  }
}

export async function GetAccountInfoApi({ BearerToken }: { BearerToken: string }) {
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/info'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetAccountInfoApi error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function UpdateAccountInfoApi({ BearerToken, Data }: { BearerToken: string, Data: object }) {
  let requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
    body: JSON.stringify(Data)
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/info'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('UpdateAccountInfoApi error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function VerifyRequestApi({ BearerToken }: { BearerToken: string }) {
  let requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
    body: JSON.stringify({})
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/verify'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('VerifyRequestApi error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function VerifyConfirmApi({ Token }: { Token: string }) {
  let requestOptions = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      token: Token
    })
  }
  try {
    let Url = ApiBaseUrl() + '/v1/accounts/verify'
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('VerifyConfirmApi error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}