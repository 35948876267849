import ApiBaseUrl from "./Defaults"

export async function GetSourcesV1Api({ projectId, BearerToken, SortColumn, SortOrder, SearchTerm, Page, Count }) {
  let queryParams = {
    'sort_column': SortColumn,
    'sort_order': SortOrder,
    'search_term': SearchTerm,
    'page': Page,
    'count': Count
  }
  let urlQueryParams = new URLSearchParams(queryParams).toString()
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/sources?' + urlQueryParams
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetSourcesV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function DeleteSourceV1Api({ projectId, BearerToken, Id }) {
  let requestOptions = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/sources/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('DeleteSourceV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function GetSourceV1Api({ projectId, BearerToken, Id }) {
  let requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + BearerToken
    },
  }
  try {
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/sources/' + Id
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('GetSourceV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function UpdateFileSourceV1Api({ projectId, BearerToken, formValues, Id=null }) {
  try {
    let formData = new FormData()
    formData.append('file', formValues['file'])
    for (let i = 0; i < formValues['file'].length; i++) {
      formData.append("files", formValues['file'][i]);
    }
    formData.append('name', formValues['name'])
    let requestOptions = {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + BearerToken,
      },
      body: formData
    }
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/sources/create/file'
    if (Id !== null){
      Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/sources/update/file/'+Id
      requestOptions.method = 'PUT'
    }
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('UpdateFileSourceV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}

export async function UpdateSourceV1Api({ projectId, BearerToken, formValues, Id=null }) {
  try {
    let jsonData = {}
    jsonData['name'] = formValues['name']
    jsonData['source_type'] = formValues['sourceType']
    jsonData['links'] = formValues['links']
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + BearerToken,
      },
      body: JSON.stringify(jsonData)
    }
    let Url = ApiBaseUrl() + '/v1/projects/' + projectId + '/sources'
    if (Id !== null){
      Url += '/' + Id
      requestOptions.method = 'PUT'
    }
    let response = await fetch(Url, requestOptions);
    let json = await response.json();
    let status = response.status
    return {
      json: json,
      status: status
    }
  } catch (error) {
    console.log('UpdateSourceV1Api error', error)
    return {
      status: 500,
      json: {
        error: error
      }
    }
  }
}