import { Group, ScrollArea, Text, Stack, TextInput, Paper, Space, Box, Loader, Center, Divider, Title, Button } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { GetQuerySessionV1Api, PostQueryV1Api } from "../../lib/Apis/Queries";
import { IconSend } from "@tabler/icons-react";
import { GetSourceV1Api } from "../../lib/Apis/Sources";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDisclosure } from "@mantine/hooks";
import { SearchQueriesModalComponent } from "./SearchQueriesModal";
import { GetGroupV1Api } from "../../lib/Apis/Groups";
import { generateRandomString } from "../../lib/Constants/Generic";

export default function ConsoleQueriesRunComponent({ BearerToken, projectId, sourceId = null, groupId = null }) {
  const [query, setQuery] = useState('')
  const [sourceName, setSourceName] = useState('')
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)
  const viewport = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const [searchModal, { open, close }] = useDisclosure(false);
  const sessionId = useRef(generateRandomString())
  const [searchParams, setSearchParams] = useSearchParams();

  function handleEndSession() {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + projectId + '/console/queries')
    }
  }
  const scrollToBottom = () =>
    viewport.current.scrollTo({ top: viewport.current.scrollHeight, behavior: 'smooth' });
  useEffect(() => {
    scrollToBottom()
  }, [loading])
  useEffect(() => {
    try {
      sessionId.current = generateRandomString()
      setResults([])
      setLoading(true)
      let response
      if (sourceId !== null) {
        response = GetSourceV1Api({ BearerToken: BearerToken, projectId: projectId, Id: sourceId })
      } else if (groupId !== null) {
        response = GetGroupV1Api({ BearerToken: BearerToken, projectId: projectId, Id: groupId })
      }
      response.then((result) => {
        if (result.status === 200) {
          setSourceName(result.json.name)
          if (searchParams.get('session') !== null && searchParams.get('session') !== '') {
            setLoading(true)
            let rsp = GetQuerySessionV1Api({ BearerToken: BearerToken, projectId: projectId, Id: searchParams.get('session') })
            rsp.then((rslt) => {
              if (rslt.status === 200) {
                sessionId.current = searchParams.get('session')
                setResults(rslt.json.data)
              } else if (rslt.status === 500) {
                CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
              } else {
                CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + rslt.json.error, Title: 'Error' })
              }
              setLoading(false)
            })
          } else {
            setSearchParams(params => {
              params.set("session", sessionId.current);
              return params;
            })
          }
        } else if (result.status === 500) {
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
        }
        setLoading(false)
      })
    } catch (error) {
      setLoading(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }, [sourceId, groupId])
  async function handleQuery(e) {
    e.preventDefault()
    try {
      if (typeof query === 'undefined' || query === '' || query === null) {
        CommonAlertsShow({ Type: 'Info', Message: 'Type a query to send', Title: 'Query Missing' })
      }
      else {
        setLoading(true)
        setResults(prevState => [{ type: 'query', query: query }, ...prevState])
        setQuery('')
        let response = PostQueryV1Api({ BearerToken: BearerToken, projectId: projectId, sourceId: sourceId, groupId: groupId, Query: query, sessionID: sessionId.current })
        response.then((result) => {
          if (result.status === 200) {
            setResults(prevState => [{ type: 'response', response: result.json.response }, ...prevState])
          } else if (result.status === 500) {
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoading(false)
        })
      }
    } catch (error) {
      setLoading(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  return (
    <>
      {
        searchModal &&
        <SearchQueriesModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          searchModal={searchModal}
          close={close}
        />
      }
      <Group position="apart">
        <Title order={4} c="grey">Query {sourceId !== null ? 'Source' : 'Group'} - {sourceName}</Title>
        <Group position="right">
          <Button variant="light" onClick={() => handleEndSession()}>End Session</Button>
          <Button variant="light" onClick={open}>Switch Session</Button>
        </Group>
      </Group>
      <Space h="sm" />
      <Divider />
      <Stack justify="flex-end">
        <Space h="md" />
        <TextInput size="sm" placeholder="Ask your query here. Eg. What are the findings of the Research?" onKeyPress={(e) => { if (e.key === 'Enter' && loading === false) { handleQuery(e) } }} value={query} onChange={(e) => setQuery(e.target.value)} rightSection={<IconSend size="0.8rem" color="gray" onClick={(e) => (loading ? e.preventDefault() : handleQuery(e))} />} />
        {loading && <Center><Loader color="gray" variant="dots" /></Center>}
        <Space h="md" />
        <ScrollArea type="auto" offsetScrollbars viewportRef={viewport}>
          {results.map(
            (result, index) => (result.type === 'query' ?
              <Box key={'box' + index}>
                <Group key={'group' + index} position="left">
                  <Paper key={'paper' + index} shadow="sm" radius="md" p="sm">
                    <Text key={'text' + index} c="gray" fw={500} fz="xs">
                      {result.query}
                    </Text>
                  </Paper>
                </Group>
                <Space key={'space' + index} h="md" />
              </Box> :
              <Box key={'box' + index}>
                <Group key={'group' + index} position="right">
                  <Paper key={'paper' + index} shadow="sm" radius="md" p="sm">
                    <Text key={'text' + index} c="grey" fw={500} fz="sm">
                      {result.response}
                    </Text>
                  </Paper>
                </Group>
                <Space key={'space' + index} h="md" />
              </Box>)
          )}
        </ScrollArea>
      </Stack>
    </>
  )
}