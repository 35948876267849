import { useEffect, useState } from 'react';
import {
  AppShell,
  Navbar,
  Header,
  Footer,
  Aside,
  Text,
  MediaQuery,
  Burger,
  useMantineTheme,
  Button,
} from '@mantine/core';
import { ConsoleSettingsAndAccountLinksComponent } from './components/Console/SettingsAndAccountLinks';
import { ConsoleMainLinksComponent } from './components/Console/MainLinks';
import ConsoleMainContentComponent from './components/Console/MainContents';
import { useNavigate, useParams } from 'react-router-dom';
import ConsoleHelpSectionComponent from './components/Console/HelpSection';

export default function ConsolePage({ selectBar = '', action = '' }) {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  const navigate = useNavigate()
  const routeParams = useParams()

  useEffect(() => {
    document.title = 'QueryMyDoc - Console';
  }, []);

  function handleConsoleClick() {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + routeParams.projectId + '/console')
    }
  }

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      navbar={
        <Navbar p="md" hiddenBreakpoint="sm" hidden={!opened} width={{ sm: 200, lg: 300 }}>
          <Navbar.Section grow mt="md">
            <ConsoleMainLinksComponent
              selectBar={selectBar}
            />
          </Navbar.Section>
          <Navbar.Section>
            <ConsoleSettingsAndAccountLinksComponent
              selectBar={selectBar}
              BearerToken={window.localStorage.getItem('BearerToken')}
            />
          </Navbar.Section>
        </Navbar>
      }
      aside={
        <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
          <Aside p="md" hiddenBreakpoint="sm" width={{ sm: 200, lg: 300 }}>
            <ConsoleHelpSectionComponent
              selectBar={selectBar}
            />
          </Aside>
        </MediaQuery>
      }
      footer={
        <Footer height={60} p="md">
        </Footer>
      }
      header={
        <Header height={{ base: 50, md: 70 }} p="md">
          <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <MediaQuery largerThan="sm" styles={{ display: 'none' }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <Button variant="subtle" compact onClick={() => handleConsoleClick()}><Text c="blue" fw={700} fz="xl">QueryMyDoc</Text></Button>
          </div>
        </Header>
      }
    >
      <ConsoleMainContentComponent
        selectBar={selectBar}
        action={action}
      />
    </AppShell>
  );
}