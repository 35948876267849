import { Button, Group, LoadingOverlay, Modal, MultiSelect, NavLink, Space, Text, TextInput } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { UpdateGroupV1Api } from "../../lib/Apis/Groups";
import { GetSourcesV1Api } from "../../lib/Apis/Sources";

export default function ConsoleGroupsCreateModalComponent({ BearerToken, projectId, createModal, close, setVersion }) {
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [sources, setSources] = useState([])

  const form = useForm({
    initialValues: {
      name: '',
      sources: []
    },

    validate: {
      name: isNotEmpty(),
      sources: isNotEmpty(),
    },
  });

  useEffect(() => {
    try {
      setLoadingOverlay(true)
      const response = GetSourcesV1Api({ BearerToken: BearerToken, projectId: projectId, SortColumn: 'name', SortOrder: 1, SearchTerm: '', Page: 1, Count: 0 })
      response.then((result) => {
        if (result.status === 200) {
          let results = []
          result.json.data.map(
            (i) => (results.push({ value: i._id, label: i.name }))
          )
          setSources(results)
        } else if (result.status === 500) {
          console.log('ConsoleGroupsCreateModalComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('ConsoleGroupsCreateModalComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      console.log('ConsoleGroupsCreateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [])

  async function handleSubmit({ values }) {
    try {
      console.log(values)
      setLoadingOverlay(true)
      let response = UpdateGroupV1Api({ formValues: values, projectId: projectId, BearerToken: BearerToken })
      response.then((result) => {
        if (result.status === 200) {
          CommonAlertsShow({
            Type: 'Success',
            Message: <NavLink
              key={result.json._id}
              label={<Text c="gray" fz="sm" fw={300}>Click here to start Querying</Text>}
              component="a"
              href={"/projects/" + projectId + "/console/queries/groups/" + result.json._id + '/run'}
            />,
            Title: 'Group Created'
          })
          setVersion(n => n + 1)
          close()
        } else if (result.status === 500) {
          console.log('ConsoleGroupsCreateModalComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('ConsoleGroupsCreateModalComponent error', result)
          if (result.json.errors !== null) {
            form.setErrors(result.json.errors)
          } else {
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      setLoadingOverlay(false)
      console.log('ConsoleGroupsCreateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  return (
    <Modal opened={createModal} onClose={close} size="md" title={<Text fw={500} c="gray">Create Group</Text>}>
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <form onSubmit={form.onSubmit((values) => handleSubmit({ values: values }))}>
        <TextInput
          label={<Text fz="sm" c="grey">Group Name</Text>}
          description="Name of the Group. Eg: Collection of Research Documents"
          placeholder="Group Name"
          size="xs"
          {...form.getInputProps('name')}
        />
        <Space h="xl" />
        <MultiSelect
          data={sources}
          placeholder="Select Sources from the list (Scroll to load more)"
          searchable
          size="xs"
          {...form.getInputProps('sources')}
        />
        <Space h="xl" />
        <Group position="right" mt="md">
          <Button type="submit" variant="light">Create</Button>
        </Group>
      </form>
    </Modal>
  )
}