

export const footerData = [
    {
      title: 'Company',
      links: [
        {
          label: 'About Us',
          link: '/about'
        },
        {
            label: 'Team',
            link: '/team'
        },
        {
            label: 'Contact',
            link: '/contat'
        }
      ]
    },
    {
        title: 'QueryMyDoc',
        links: [
          {
            label: 'Support',
            link: '/support'
          },
          {
              label: 'Pricing',
              link: '/pricing'
          },
          {
            label: 'Privacy',
            link: '/privacy'
          },
          {
            label: 'Terms',
            link: '/terms'
          },
        ]
      }
  ];