import { ActionIcon, Divider, Group, LoadingOverlay, ScrollArea, Space, Table, Text } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";


export default function TablesSimpleComponent({ Columns, PrimaryRowActions, GetRowsFn, BearerToken, projectId=null, version }) {
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [tableRows, setTableRows] = useState([]);

  useEffect(() => {
    handleFetchRows()
  }, [version]);

  async function handleFetchRows() {
    try {
      setLoadingOverlay(true)
      let response = GetRowsFn({ BearerToken: BearerToken, projectId: projectId })
      response.then((result) => {
        if (result.status === 200) {
          let newTableRows = []
          result.json.data.forEach(
            (i) => {
              let newTableRow = {}
              Columns.map(
                (column) => (newTableRow[column.id] = typeof i[column.apiKey] === 'undefined' ? '' : i[column.apiKey])
              )
              newTableRow['id'] = i['_id']
              newTableRows.push(newTableRow)
            }
          )
          setTableRows(newTableRows)
        } else if (result.status === 500) {
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: '+result.json.error, Title: 'Error' })
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      setLoadingOverlay(false)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }

  function GetNoDataRow() {
    return (
      <tr key={"EmptyRow"}>
        <td key={"EmptyColumn"} colSpan={Columns.length + 1} align='center'>
          <Text fz="sm" c="gray" fw={500}>No Data</Text>
        </td>
      </tr>
    )
  }

  function getTableRow(Row, Columns) {
    return (
      <tr key={Row.id}>
        {Columns.map(
          (Column, index) => (
            <td key={Row.id + ':' + index}><Text c="grey" fw={400} fz="sm">
              {
                Column.type === 'string' ?
                  Row[`${Column.id}`] :
                  Column.type === 'objectArray' ?
                    Row[`${Column.id}`].map((subRow) => `[${subRow.name}]`) :
                    Column.type === 'bool' &&
                    (Row[`${Column.id}`] === true ?
                      <IconCheck size="1rem" color="grey" /> :
                      <IconX size="1rem" color="grey" />)
              }</Text></td>
          )
        )}
        <td key={Row.id + ':actions'}>
          <Group>
            {PrimaryRowActions.map(
              (action, index) => (
                <ActionIcon key={'PrimaryRowActions' + index} variant="transparent" onClick={(e) => { action.HandleFunction(e, Row) }} title={action.Title}>
                  {action.Icon}
                </ActionIcon>
              )
            )}
          </Group>
        </td>
      </tr>
    )
  }

  function GetTableColumn(Column) {
    return (
      <th key={Column.id}>
        <Text fw={500} fz="sm" c="grey">
          {Column.label}
        </Text>
      </th>
    )
  }

  return (
    <ScrollArea>
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <Space h="xs" />
      <Table highlightOnHover>
        <thead>
          <tr>
            {
              Columns.map((Column) => GetTableColumn(Column))
            }
            <th key={"Actions"}><Text fz="sm" c="gray" fw={600}>Actions</Text></th>
          </tr>
        </thead>
        <tbody>
          {
            tableRows.length > 0 ? tableRows.map((tableRow) => getTableRow(tableRow, Columns)) : GetNoDataRow()
          }
        </tbody>
      </Table>
      <Divider />
      <Space h="xs" />
    </ScrollArea>
  )
}