import { Box, Button, Center, Checkbox, FileInput, Group, LoadingOverlay, Modal, MultiSelect, NavLink, SegmentedControl, Space, Text, TextInput, rem } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { IconFileDownload, IconUpload, IconWorldDownload } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { GetSourceV1Api, UpdateFileSourceV1Api, UpdateSourceV1Api } from "../../lib/Apis/Sources";

export default function ConsoleSourcesUpdateModalComponent({ BearerToken, projectId, editModal, close, setVersion, rowId }) {
  const [loadingOverlay, setLoadingOverlay] = useState(true)

  const form = useForm({
    initialValues: {
      name: '',
      sourceType: 'Web',
      file: null,
      links: [],
      changeSource: false
    },

    validate: {
      name: isNotEmpty(),
    },
  });

  useEffect(() => {
    try {
      if (rowId !== null && rowId !== '') {
        setLoadingOverlay(true)
        const response = GetSourceV1Api({ BearerToken: BearerToken, projectId: projectId, Id: rowId })
        response.then((result) => {
          if (result.status === 200) {
            form.setValues({ name: result.json.name, sourceType: result.json.source_type, links: result.json.links })
          } else if (result.status === 500) {
            console.log('ConsoleSourcesUpdateModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleSourcesUpdateModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoadingOverlay(false)
        })
      }
    } catch (error) {
      console.log('ConsoleSourcesUpdateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [rowId])

  async function handleSubmit({ values }) {
    try {
      if (values['sourceType'] === 'Upload') {
        if (typeof values['file'] === 'undefined' || values['file'] === '' || values['file'] === null) {
          setLoadingOverlay(true)
          let response = UpdateSourceV1Api({ formValues: values, projectId: projectId, BearerToken: BearerToken, Id: rowId })
          response.then((result) => {
            if (result.status === 200) {
              CommonAlertsShow({
                Type: 'Success',
                Message: <NavLink
                  key={result.json._id}
                  label={<Text c="gray" fz="sm" fw={300}>Click here to start Querying</Text>}
                  component="a"
                  href={"/projects/" + projectId + "/console/queries/sources/" + result.json._id + '/run'}
                />,
                Title: 'Upload Source Updated'
              })
              setVersion(n => n + 1)
              close()
            } else if (result.status === 500) {
              console.log('ConsoleSourcesUpdateModalComponent error', result)
              CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
            } else {
              console.log('ConsoleSourcesUpdateModalComponent error', result)
              if (result.json.errors !== null) {
                form.setErrors(result.json.errors)
              } else {
                CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
              }
            }
            setLoadingOverlay(false)
          })
        } else {
          setLoadingOverlay(true)
          let response = UpdateFileSourceV1Api({ formValues: values, projectId: projectId, BearerToken: BearerToken, Id: rowId })
          response.then((result) => {
            if (result.status === 200) {
              CommonAlertsShow({
                Type: 'Success',
                Message: <NavLink
                  key={result.json._id}
                  label={<Text c="gray" fz="sm" fw={300}>Click here to start Querying</Text>}
                  component="a"
                  href={"/projects/" + projectId + "/console/queries/sources/" + result.json._id + '/run'}
                />,
                Title: 'Upload Source Updated'
              })
              setVersion(n => n + 1)
              close()
            } else if (result.status === 500) {
              console.log('ConsoleSourcesUpdateModalComponent error', result)
              CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
            } else {
              console.log('ConsoleSourcesUpdateModalComponent error', result)
              if (result.json.errors !== null) {
                form.setErrors(result.json.errors)
              } else {
                CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
              }
            }
            setLoadingOverlay(false)
          })
        }
      } else {
        setLoadingOverlay(true)
        let response = UpdateSourceV1Api({ formValues: values, projectId: projectId, BearerToken: BearerToken, Id: rowId })
        response.then((result) => {
          if (result.status === 200) {
            CommonAlertsShow({
              Type: 'Success',
              Message: <NavLink
                key={result.json._id}
                label={<Text c="gray" fz="sm" fw={300}>Click here to start Querying</Text>}
                component="a"
                href={"/projects/" + projectId + "/console/queries/sources/" + result.json._id + '/run'}
              />,
              Title: 'Web Source Updated'
            })
            setVersion(n => n + 1)
            close()
          } else if (result.status === 500) {
            console.log('ConsoleSourcesUpdateModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleSourcesUpdateModalComponent error', result)
            if (result.json.errors !== null) {
              form.setErrors(result.json.errors)
            } else {
              CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
            }
          }
          setLoadingOverlay(false)
        })
      }
    } catch (error) {
      setLoadingOverlay(false)
      console.log('ConsoleSourcesUpdateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  return (
    <Modal opened={editModal} onClose={close} size="md" title={<Text fw={500} c="gray">Update Source</Text>}>
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <form onSubmit={form.onSubmit((values) => handleSubmit({ values: values }))}>
        <TextInput
          label={<Text fz="sm" c="grey">Source Name</Text>}
          description="Name of the Source. Eg: Research Document"
          placeholder="Source Name"
          size="xs"
          {...form.getInputProps('name')}
        />
        <Checkbox
          mt="xs"
          label={<Text c="gray" fz="xs">Change Source Type and Files/Links</Text>}
          {...form.getInputProps('changeSource', { type: 'checkbox' })}
        />
        {
          form.values['changeSource'] &&
          <>
            <Space h="xl" />
            <SegmentedControl
              fullWidth
              data={[
                {
                  value: 'Web',
                  label: (
                    <Center>
                      <IconWorldDownload size="1rem" color="grey" />
                      <Box ml={10}><Text fw={500} fz="sm" c="grey">Web Page(s)</Text></Box>
                    </Center>
                  ),
                },
                {
                  value: 'Upload',
                  label: (
                    <Center>
                      <IconFileDownload size="1rem" color="grey" />
                      <Box ml={10}><Text fw={500} fz="sm" c="grey">Upload File(s)</Text></Box>
                    </Center>
                  ),
                },
              ]}
              {...form.getInputProps('sourceType')}
            />
            {
              form.values['sourceType'] === 'Upload' ?
                <FileInput multiple size="xs" placeholder="Select files to upload (You can select multiple files from a folder)" icon={<IconUpload size={rem(14)} />} {...form.getInputProps('file')} />
                :
                <MultiSelect
                  data={form.values['links']}
                  defaultValue={form.values['links']}
                  placeholder="Enter Web links to upload (You can enter multiple links)"
                  creatable
                  searchable
                  size="xs"
                  getCreateLabel={(query) => `+ ADD ${query}`}
                  onCreate={(query) => {
                    let linksData = form.values['links']
                    linksData.push(query)
                    form.setValues({ links: linksData })
                    return query;
                  }}
                  {...form.getInputProps('links')}
                />
            }
          </>
        }
        <Space h="xl" />
        <Group position="right" mt="md">
          <Button type="submit" variant="light">Update</Button>
        </Group>
      </form>
    </Modal>
  )
}