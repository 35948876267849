import { footerData } from '../../lib/Constants/Index'
import {
  TextInput,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
  Space,
} from '@mantine/core';
import { useState } from 'react';
import { AccountRecoveryRequestApi } from '../../lib/Apis/Accounts';
import { useNavigate } from 'react-router-dom';
import { HomeFooterComponent } from '../Home/Footer';
import { CommonAlertsShow } from '../Common/Alerts';

export default function AccountsRecoveryRequestComponent() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [serverError, setServerError] = useState('');
  const [successMsg, setSuccessMgs] = useState('')
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(false)
  const navigate = useNavigate()

  function handleEmailTypeEvent(e) {
    setEmail(e.target.value)
  }
  function handleSignIn() {
    if (typeof window !== 'undefined' && window.localStorage.getItem('BearerToken') !== null) {
      navigate('/console/projects')
    }
  }
  function handleSignUp() {
    if (typeof window !== 'undefined') {
      navigate('/signup')
    }
  }
  async function handleRecoveryRequest() {
    setLoadingOverlay(true)
    setEmailError('')
    setServerError('')
    const response = await AccountRecoveryRequestApi({ email: email })
    setLoadingOverlay(false)
    if (response.status === 200) {
      setButtonStatus(true)
      setSuccessMgs('Password reset instructions have sent to your email')
      CommonAlertsShow({ Type: 'Success', Message: 'Password reset instructions have sent to your email', Title: 'Success' })
    } else if (response.status === 400) {
      if ('errors' in response.json) {
        if ('email' in response.json.errors) {
          setEmailError(response.json.errors.email)
        }
      }
    } else if (response.status === 500) {
      if ('error' in response.json) {
        setServerError(response.json.error)
      } else {
        setServerError('Internal error')
      }
    }
  }
  return (
    <>
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
        >
          Forgot Password
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Enter your account email to reset your password
        </Text>
        <Space h="xl" />
        <Space h="xl" />
        <Space h="xl" />
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
          <TextInput disabled={buttonStatus} label="Email" placeholder="name@email.com" required value={email} onChange={handleEmailTypeEvent} />
          <Text fz="sm" c="red">{emailError}</Text>
          <Text fz="sm" c="red">{serverError}</Text>
          {
            successMsg !== '' &&
            <Text fz="sm" fw={500} c="green">{successMsg}</Text>
          }
          <Button disabled={buttonStatus} fullWidth mt="xl" onClick={() => handleRecoveryRequest()}>
            Reset Password
          </Button>
          <Group position="apart" mt="lg">
            <Anchor size="sm" component="button" onClick={() => handleSignIn()}>
              Sign In
            </Anchor>
            <Anchor size="sm" component="button" onClick={() => handleSignUp()}>
              Sign Up
            </Anchor>
          </Group>
        </Paper>
      </Container>
      <HomeFooterComponent
        data={footerData}
      />
    </>
  );
}
