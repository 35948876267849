import { Routes, Route, BrowserRouter} from "react-router-dom";
import HomePage from "./home";
import ConsolePage from "./console";
import ConsoleProtectedRoute from "./components/Console/ProtectedRoute";
import ConsoleProjectRoute from "./components/Console/ProjectRoute";

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="" element={<HomePage/>} />
        <Route path="/signin" element={<HomePage selectBar="siginIn"/>} />
        <Route path="/signup" element={<HomePage selectBar="siginUp"/>} />
        <Route path="/recovery" element={<HomePage selectBar="recoveryRequest"/>} />
        <Route path="/recovery/:token" element={<HomePage selectBar="recoveryConfirm"/>} />
        <Route path="/verify/:token" element={<HomePage selectBar="verifyConfirm"/>} />
        <Route path="/console/projects" element={ <ConsoleProtectedRoute> <ConsolePage selectBar="projects"/> </ConsoleProtectedRoute> } />
        <Route path="/console/settings/:tabValue" element={ <ConsoleProtectedRoute> <ConsolePage selectBar="settings"/> </ConsoleProtectedRoute> } />
        <Route path="/projects/:projectId/console" element={ <ConsoleProtectedRoute> <ConsoleProjectRoute> <ConsolePage /> </ConsoleProjectRoute> </ConsoleProtectedRoute> }/>
        <Route path="/projects/:projectId/console/queries" element={ <ConsoleProtectedRoute> <ConsoleProjectRoute> <ConsolePage selectBar="queries"/> </ConsoleProjectRoute> </ConsoleProtectedRoute> }/>
        <Route path="/projects/:projectId/console/sources" element={ <ConsoleProtectedRoute> <ConsoleProjectRoute> <ConsolePage selectBar="sources"/> </ConsoleProjectRoute> </ConsoleProtectedRoute> }/>
        <Route path="/projects/:projectId/console/groups" element={ <ConsoleProtectedRoute> <ConsoleProjectRoute> <ConsolePage selectBar="groups"/> </ConsoleProjectRoute> </ConsoleProtectedRoute> }/>
        <Route path="/projects/:projectId/console/queries/sources/:sourceId/run" element={ <ConsoleProtectedRoute> <ConsoleProjectRoute> <ConsolePage selectBar="queries" action="run"/> </ConsoleProjectRoute> </ConsoleProtectedRoute> }/>
        <Route path="/projects/:projectId/console/queries/groups/:groupId/run" element={ <ConsoleProtectedRoute> <ConsoleProjectRoute> <ConsolePage selectBar="queries" action="run"/> </ConsoleProjectRoute> </ConsoleProtectedRoute> }/>
      </Routes>
    </BrowserRouter>
  );
}