import { Container, Group, LoadingOverlay, Modal, Paper, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { GetSourceV1Api } from "../../lib/Apis/Sources";
import { IconSoup } from "@tabler/icons-react";

export default function ConsoleSourcesViewModalComponent({ BearerToken, projectId, viewModal, close, rowId }) {
  const [loadingOverlay, setLoadingOverlay] = useState(true)
  const [sourceName, setSourceName] = useState('Source')
  const [sourceType, setSourceType] = useState('')
  const [documentTexts, setDocumentTexts] = useState([])

  useEffect(() => {
    try {
      if (rowId !== null && rowId !== '') {
        setLoadingOverlay(true)
        const response = GetSourceV1Api({ BearerToken: BearerToken, projectId: projectId, Id: rowId })
        response.then((result) => {
          if (result.status === 200) {
            setSourceName(result.json.name)
            setSourceType(result.json.source_type)
            let docTexts = []
            result.json.documents.forEach(
              (document) => {
                docTexts.push(document.text !== null && document.text !== '' ? document.text : null)
              }
            )
            setDocumentTexts(docTexts)
          } else if (result.status === 500) {
            console.log('ConsoleSourcesViewModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleSourcesViewModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoadingOverlay(false)
        })
      }
    } catch (error) {
      console.log('ConsoleSourcesViewModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [rowId])

  return (
    <Modal opened={viewModal} onClose={close} size="lg">
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <Paper shadow="sm" radius="md" p="sm">
        <Group position="apart">
          <IconSoup
            size={60}
            strokeWidth={1.5}
            color={'#228BE6'}
          />
          <Text fw={500} c="gray">{sourceName}</Text>
          <Text fw={500} c="gray">{sourceType}</Text>
          <Container size="xs" px="xs"><Text size="sm" color="dimmed">
            {documentTexts.map(
              (documentText, index) => (
                <Text key={index} c="grey" fz="sm" fw={300}>Document #{index + 1} Text: {documentText}</Text>
              )
            )}
          </Text></Container>
        </Group>
      </Paper>
    </Modal>
  )
}