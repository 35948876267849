import { footerData } from '../../lib/Constants/Index'
import {
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
  LoadingOverlay,
  Space,
  PasswordInput,
} from '@mantine/core';
import { useState } from 'react';
import { AccountRecoveryConfirmApi, CheckPassword } from '../../lib/Apis/Accounts';
import { useNavigate, useParams } from 'react-router-dom';
import { HomeFooterComponent } from '../Home/Footer';
import { CommonAlertsShow } from '../Common/Alerts';

export default function AccountsRecoveryConfirmComponent() {
  const routeParams = useParams()
  const [password, setPassword] = useState('')
  const [serverError, setServerError] = useState('');
  const [successMsg, setSuccessMgs] = useState('')
  const [passwordError, setPasswordError] = useState('')
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const [buttonStatus, setButtonStatus] = useState(false)
  const navigate = useNavigate()

  function handlePasswordTypeEvent(e) {
    setPasswordError('')
    setPassword(e.target.value)
    let errors = CheckPassword({password: e.target.value})
    if (errors.length > 0) {
      setPasswordError(errors.join(', '))
    }
  }
  function handleSignIn() {
    if (typeof window !== 'undefined' && window.localStorage.getItem('BearerToken') !== null) {
      navigate('/console/projects')
    }
  }
  function handleSignUp() {
    if (typeof window !== 'undefined') {
      navigate('/signup')
    }
  }
  async function handleRecoveryConfirm() {
    setServerError('')
    let errors = CheckPassword({password: password})
    if (errors.length > 0) {
      setPasswordError(errors.join(', '))
      CommonAlertsShow({ Type: 'Error', Message: errors.join(', '), Title: 'Password Error' })
      return
    }
    setPasswordError('')
    setLoadingOverlay(true)
    const response = await AccountRecoveryConfirmApi({ password: password, token: routeParams.token })
    setLoadingOverlay(false)
    if (response.status === 200) {
      setButtonStatus(true)
      setSuccessMgs('Password reset complete. SignIn to Continue')
      CommonAlertsShow({ Type: 'Success', Message: 'Password reset complete. SignIn to Continue', Title: 'Success' })
    } else if (response.status === 400) {
      if ('errors' in response.json) {
        if ('password' in response.json.errors) {
          setPasswordError(response.json.errors.password)
        }
      }
    } else if (response.status === 500) {
      if ('error' in response.json) {
        setServerError(response.json.error)
      } else {
        setServerError('Internal error')
      }
    }
  }
  return (
    <>
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
        >
          Set Password
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
          Enter your new password here
        </Text>
        <Space h="xl" />
        <Space h="xl" />
        <Space h="xl" />
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
          <PasswordInput disabled={buttonStatus} label="Password" placeholder="Your password" required mt="md" onChange={handlePasswordTypeEvent} value={password} />
          <Text fz="sm" c="red">{passwordError}</Text>
          <Text fz="sm" c="red">{serverError}</Text>
          {
            successMsg !== '' &&
            <Text fz="sm" fw={500} c="green">{successMsg}</Text>
          }
          <Button disabled={buttonStatus} fullWidth mt="xl" onClick={() => handleRecoveryConfirm()}>
            Set Password
          </Button>
          <Group position="apart" mt="lg">
            <Anchor size="sm" component="button" onClick={() => handleSignIn()}>
              Sign In
            </Anchor>
            <Anchor size="sm" component="button" onClick={() => handleSignUp()}>
              Sign Up
            </Anchor>
          </Group>
        </Paper>
      </Container>
      <HomeFooterComponent
        data={footerData}
      />
    </>
  );
}
