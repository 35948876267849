import { Button, Divider, Group, Paper, Space, Title } from "@mantine/core";
import { TablesCustomComponent } from "../Tables/Custom";
import { DeleteGroupV1Api, GetGroupsV1Api } from "../../lib/Apis/Groups";
import { useDisclosure } from "@mantine/hooks";
import ConsoleGroupsCreateModalComponent from "./GroupsCreateModal";
import { useState } from "react";
import ConsoleGroupsViewModalComponent from "./GroupsViewModal";
import ConsoleGroupsUpdateModalComponent from "./GroupsUpdateModal";
import { useNavigate } from "react-router-dom";
import { IconSoup } from "@tabler/icons-react";

export default function ConsoleGroupsIndexComponent({ BearerToken, projectId }) {
  const [createModal, createModalHandlers] = useDisclosure(false);
  const [viewModal, viewModalHandlers] = useDisclosure(false);
  const [editModal, editModalHandlers] = useDisclosure(false);
  const [selectedRow, setSelectedRow] = useState({})
  const [version, setVersion] = useState(1)
  const navigate = useNavigate()
  function handleRunQuery(e, Row) {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + projectId + '/console/queries/groups/' + Row.id + '/run')
    }
  }
  return (
    <>
      {
        createModal &&
        <ConsoleGroupsCreateModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          createModal={createModal}
          close={createModalHandlers.close}
          setVersion={setVersion}
        />
      }
      {
        viewModal &&
        <ConsoleGroupsViewModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          viewModal={viewModal}
          close={viewModalHandlers.close}
          rowId={selectedRow}
        />
      }
      {
        editModal &&
        <ConsoleGroupsUpdateModalComponent
          BearerToken={BearerToken}
          projectId={projectId}
          editModal={editModal}
          close={editModalHandlers.close}
          rowId={selectedRow}
          setVersion={setVersion}
        />
      }
      <Group position="apart">
        <Title order={4} c="grey">Groups</Title>
        <Button variant="light" onClick={createModalHandlers.open}>Create Group</Button>
      </Group>
      <Space h="sm" />
      <Divider />
      <Space h="xl" />
      <Paper shadow="sm" radius="md" p="sm">
        <TablesCustomComponent
          BearerToken={BearerToken}
          projectId={projectId}
          Columns={[
            { id: 'name', type: 'string', label: 'Name', apiKey: 'name' },
            { id: 'sources', type: 'objectArray', label: 'Sources', apiKey: 'sources' },
            { id: 'createdAt', type: 'string', label: 'Created At', apiKey: 'created_at' },
          ]}
          GetRowsFn={GetGroupsV1Api}
          DeleteRowFn={DeleteGroupV1Api}
          ViewRowFn={viewModalHandlers.open}
          EditRowFn={editModalHandlers.open}
          PrimaryRowActions={[{ Title: 'Start Query Session', Icon: <IconSoup size="1rem" color="black" />, HandleFunction: handleRunQuery }]}
          version={version}
          setSelectedRow={setSelectedRow}
        />
      </Paper>
    </>
  )
}