import { Button, Divider, Group, Paper, Space, Title } from "@mantine/core";
import { TablesCustomComponent } from "../Tables/Custom";
import { DeleteProjectV1Api, GetProjectsV1Api } from "../../lib/Apis/Projects";
import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IconSwitchHorizontal } from "@tabler/icons-react";
import ConsoleProjectsCreateModalComponent from "./ProjectsCreateModal";
import ConsoleProjectsViewModalComponent from "./ProjectsViewModal";
import ConsoleProjectsUpdateModalComponent from "./ProjectsUpdateModal";
import { CommonAlertsShow } from "../Common/Alerts";

export default function ConsoleProjectsIndexComponent({ BearerToken }) {
  const [createModal, createModalHandlers] = useDisclosure(false);
  const [viewModal, viewModalHandlers] = useDisclosure(false);
  const [editModal, editModalHandlers] = useDisclosure(false);
  const [selectedRow, setSelectedRow] = useState({})
  const [version, setVersion] = useState(1)
  const navigate = useNavigate()
  function handleSwitchProject(e, Row) {
    if (typeof window !== 'undefined') {
      navigate('/projects/' + Row.id + '/console')
      CommonAlertsShow({ Type: 'Success', Message: 'Current Project: ' + Row.name, Title: 'Switched Project' })
    }
  }
  return (
    <>
      {
        createModal &&
        <ConsoleProjectsCreateModalComponent
          BearerToken={BearerToken}
          createModal={createModal}
          close={createModalHandlers.close}
          setVersion={setVersion}
        />
      }
      {
        viewModal &&
        <ConsoleProjectsViewModalComponent
          BearerToken={BearerToken}
          viewModal={viewModal}
          close={viewModalHandlers.close}
          rowId={selectedRow}
        />
      }
      {
        editModal &&
        <ConsoleProjectsUpdateModalComponent
          BearerToken={BearerToken}
          editModal={editModal}
          close={editModalHandlers.close}
          rowId={selectedRow}
          setVersion={setVersion}
        />
      }
      <Group position="apart">
        <Title order={4} c="grey">Projects</Title>
        <Button variant="light" onClick={createModalHandlers.open}>Create Project</Button>
      </Group>
      <Space h="sm" />
      <Divider />
      <Space h="xl" />
      <Paper shadow="sm" radius="md" p="sm">
        <TablesCustomComponent
          BearerToken={BearerToken}
          Columns={[
            { id: 'name', type: 'string', label: 'Name', apiKey: 'name' },
            { id: 'default', type: 'bool', label: 'Default Project', apiKey: 'default' },
            { id: 'timezone', type: 'string', label: 'Timezone', apiKey: 'timezone' },
            { id: 'createdAt', type: 'string', label: 'Created At', apiKey: 'created_at' },
          ]}
          GetRowsFn={GetProjectsV1Api}
          DeleteRowFn={DeleteProjectV1Api}
          ViewRowFn={viewModalHandlers.open}
          EditRowFn={editModalHandlers.open}
          PrimaryRowActions={[{ Title: 'Switch to Project', Icon: <IconSwitchHorizontal size="1rem" color="black" />, HandleFunction: handleSwitchProject }]}
          version={version}
          setSelectedRow={setSelectedRow}
        />
      </Paper>
    </>
  )
}