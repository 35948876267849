import { Container, Group, LoadingOverlay, Modal, Paper, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { GetProjectV1Api } from "../../lib/Apis/Projects";
import { IconCheck, IconSettings2, IconX } from "@tabler/icons-react";

export default function ConsoleProjectsViewModalComponent({ BearerToken, viewModal, close, rowId }) {
  const [loadingOverlay, setLoadingOverlay] = useState(true)
  const [projectName, setProjectName] = useState('Project')
  const [timezone, setTimezone] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [updatedAt, setUpdatedAt] = useState('')
  const [openaiApiKeyOverride, setOpenAiApiKeyOverride] = useState(false)
  const [openaiApiKey, setOpenAiApiKey] = useState('')
  const [Default, setDefault] = useState(false)

  useEffect(() => {
    try {
      if (rowId !== null && rowId !== '') {
        setLoadingOverlay(true)
        const response = GetProjectV1Api({ BearerToken: BearerToken, Id: rowId })
        response.then((result) => {
          if (result.status === 200) {
            setProjectName(result.json.name)
            setTimezone(result.json.timezone)
            setDefault(result.json.default)
            setCreatedAt(result.json.created_at)
            setUpdatedAt(result.json.updated_at)
            setOpenAiApiKey(result.json.openai_api_key)
            setOpenAiApiKeyOverride(result.json.openai_api_key_override)
          } else if (result.status === 500) {
            console.log('ConsoleProjectsViewModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleProjectsViewModalComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoadingOverlay(false)
        })
      }
    } catch (error) {
      console.log('ConsoleProjectsViewModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [rowId])

  return (
    <Modal opened={viewModal} onClose={close} size="lg">
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <Paper shadow="sm" radius="md" p="sm">
        <Group position="apart">
          <IconSettings2
            size={60}
            strokeWidth={1.5}
            color={'#228BE6'}
          />
          <Text fw={500} c="gray">{projectName}</Text>
          <Container size="xs" px="xs">
            <Text fw={400} fz="sm" c="gray">Timezone: {timezone}</Text>
            <Text fw={400} fz="sm" c="gray">Default Project: {Default === true ?
              <IconCheck size="1rem" color="grey" /> :
              <IconX size="1rem" color="grey" />}
            </Text>
            <Text fw={400} fz="sm" c="gray">OpenAI API Key Override: {openaiApiKeyOverride === true ?
              <IconCheck size="1rem" color="grey" /> :
              <IconX size="1rem" color="grey" />}
            </Text>
            {
              openaiApiKeyOverride === true &&
              <Text fw={400} fz="sm" c="gray">OpenAI API Key: {openaiApiKey}</Text>
            }
            <Text fw={400} fz="sm" c="gray">Created At: {createdAt}</Text>
            <Text fw={400} fz="sm" c="gray">Updated At: {updatedAt}</Text>
          </Container>
        </Group>
      </Paper>
    </Modal>
  )
}