import { Welcome } from './components/Welcome/Welcome';
import { HomeHeaderComponent } from './components/Home/Header';
import { HomeFooterComponent } from './components/Home/Footer';
import { footerData } from './lib/Constants/Index'
import AccountsSignInComponent from './components/Accounts/SignIn';
import AccountsSignUpComponent from './components/Accounts/SignUp';
import AccountsRecoveryRequestComponent from './components/Accounts/RecoveryRequest';
import AccountsRecoveryConfirmComponent from './components/Accounts/RecoveryConfirm';
import AccountsVerifyConfirmComponent from './components/Accounts/VerifyConfirm';

export default function HomePage({ selectBar = '' }) {
  if (selectBar === '' || selectBar === '/') {
    document.title = 'QueryMyDoc - Home'
    return (
      <>
        <HomeHeaderComponent />
        <Welcome />
        <HomeFooterComponent
          data={footerData}
        />
      </>
    )
  } else if (selectBar === 'siginIn') {
    document.title = 'QueryMyDoc - Sign In'
    return (
      <AccountsSignInComponent />
    )
  } else if (selectBar === 'siginUp') {
    document.title = 'QueryMyDoc - Sign Up'
    return (
      <AccountsSignUpComponent />
    )
  } else if (selectBar === 'recoveryRequest') {
    document.title = 'QueryMyDoc - Forgot Password'
    return (
      <AccountsRecoveryRequestComponent />
    )
  } else if (selectBar === 'recoveryConfirm') {
    document.title = 'QueryMyDoc - Set Password'
    return (
      <AccountsRecoveryConfirmComponent />
    )
  } else if (selectBar === 'verifyConfirm') {
    document.title = 'QueryMyDoc - Set Password'
    return (
      <AccountsVerifyConfirmComponent />
    )
  }
}