import { footerData } from '../../lib/Constants/Index'
import {
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  LoadingOverlay,
  Space,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { HomeFooterComponent } from '../Home/Footer';
import { CommonAlertsShow } from '../Common/Alerts';
import { useNavigate, useParams } from 'react-router-dom';
import { VerifyConfirmApi } from '../../lib/Apis/Accounts';

export default function AccountsVerifyConfirmComponent() {
  const routeParams = useParams()
  const [status, setstatus] = useState('')
  const [code, setCode] = useState(0)
  const [loadingOverlay, setLoadingOverlay] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    try {
      setLoadingOverlay(true)
      const response = VerifyConfirmApi({ Token: routeParams.token })
      response.then((result) => {
        if (result.status === 200) {
          setstatus('Your Email is verified successfully. Click Sign In to continue using your account')
          setCode(200)
          CommonAlertsShow({ Type: 'Success', Message: 'Email Verified', Title: 'Success' })
        } else if (result.status === 500) {
          console.log('AccountsVerifyConfirmComponent error', result)
          setstatus('Server Error')
          setCode(500)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('AccountsVerifyConfirmComponent error', result)
          setstatus(result.json.error)
          setCode(400)
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      console.log('AccountsVerifyConfirmComponent error', error)
      setstatus('Server Error')
      setCode(500)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [])

  function handleSignIn() {
    if (typeof window !== 'undefined' && window.localStorage.getItem('BearerToken') !== null) {
      navigate('/console/projects')
    }
  }
  function handleSignUp() {
    if (typeof window !== 'undefined') {
      navigate('/signup')
    }
  }

  return (
    <>
      <Container size={420} my={40}>
        <Title
          align="center"
          sx={(theme) => ({ fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900 })}
        >
          Verification Status
        </Title>
        <Space h="xl" />
        <Space h="xl" />
        <Space h="xl" />
        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
          <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
          <Group position="center">
            {
              code === 200 ?
                <Text fz="sm" fw={500} c="green">{status}</Text> :
                <Text fz="sm" fw={500} c="red">{status}</Text>
            }
          </Group>
          <Group position="apart" mt="lg">
            <Anchor size="sm" component="button" onClick={() => handleSignIn()}>
              Sign In
            </Anchor>
            <Anchor size="sm" component="button" onClick={() => handleSignUp()}>
              Sign Up
            </Anchor>
          </Group>
        </Paper>
      </Container>
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <Space h="xl" />
      <HomeFooterComponent
        data={footerData}
      />
    </>
  );
}
