import { Button, Checkbox, Group, LoadingOverlay, Modal, NativeSelect, NavLink, Space, Text, TextInput } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { UpdateProjectV1Api } from "../../lib/Apis/Projects";
import { CommonTimeZones } from "../../lib/Constants/Generic"

export default function ConsoleProjectsCreateModalComponent({ BearerToken, createModal, close, setVersion }) {
  const [loadingOverlay, setLoadingOverlay] = useState(false)
  const TimeZones = CommonTimeZones()

  const form = useForm({
    initialValues: {
      name: '',
      timezone: 'GMT',
      default: false,
      openAiApiKeyOverride: false,
      openAiApiKey: ''
    },

    validate: {
      name: isNotEmpty(),
      timezone: isNotEmpty(),
    },
  });

  async function handleSubmit({ values }) {
    try {
      console.log(values)
      setLoadingOverlay(true)
      let response = UpdateProjectV1Api({ formValues: values, BearerToken: BearerToken })
      response.then((result) => {
        if (result.status === 200) {
          CommonAlertsShow({
            Type: 'Success',
            Message: <NavLink
              key={result.json._id}
              label={<Text c="gray" fz="sm" fw={300}>Click here to use Project</Text>}
              component="a"
              href={"/projects/" + result.json._id + "/console"}
            />,
            Title: 'Project Created'
          })
          setVersion(n => n + 1)
          close()
        } else if (result.status === 500) {
          console.log('ConsoleProjectsCreateModalComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('ConsoleProjectsCreateModalComponent error', result)
          if (result.json.errors !== null) {
            form.setErrors(result.json.errors)
          } else {
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      setLoadingOverlay(false)
      console.log('ConsoleProjectsCreateModalComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
    }
  }
  return (
    <Modal opened={createModal} onClose={close} size="md" title={<Text fw={500} c="grey">Create Project</Text>}>
      <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
      <form onSubmit={form.onSubmit((values) => handleSubmit({ values: values }))}>
        <TextInput
          label={<Text fz="sm" c="grey">Project Name</Text>}
          description="Name of the Project. Eg: Personal Project"
          placeholder="Project Name"
          size="xs"
          {...form.getInputProps('name')}
        />
        <Space h="xl" />
        <Checkbox
          mt="md"
          label={<Text fz="sm" c="grey">Make this Project as default</Text>}
          description={<Text fz="xs" c="grey">Default Project is the Project user is shown after signin.</Text>}
          {...form.getInputProps('default', { type: 'checkbox' })}
        />
        <Space h="xl" />
        <NativeSelect
          data={TimeZones}
          size="xs"
          label={<Text fz="sm" c="grey">Timezone</Text>}
          description="Select timezone for the Project"
          {...form.getInputProps('timezone')}
        />
        <Space h="xl" />
        <Checkbox
          mt="md"
          label={<Text fz="sm" c="grey">Use your own OpenAI API Key</Text>}
          description={<Text fz="xs" c="grey">You can use your own OpenAI API Key instead of QueryMyDoc's OpenAI API Key by selecting this option.</Text>}
          {...form.getInputProps('openAiApiKeyOverride', { type: 'checkbox' })}
        />
        <Space h="xl" />
        <TextInput
          disabled={form.values['openAiApiKeyOverride'] !== true}
          label={<Text fz="sm" c="grey">OpenAI API Key</Text>}
          description="Your OpenAI API Key. Its available at https://platform.openai.com/account/api-keys"
          placeholder="OpenAI API Key"
          size="xs"
          {...form.getInputProps('openAiApiKey')}
        />
        <Space h="xl" />
        <Group position="right" mt="md">
          <Button type="submit" variant="light">Create</Button>
        </Group>
      </form>
    </Modal>
  )
}