import { Space, Tabs, Text } from "@mantine/core";
import { useNavigate, useParams } from "react-router-dom";
import ConsoleAccountSettingsComponent from "./AccountSettings";
import ConsoleProjectsIndexComponent from "./ProjectsIndex";
import { IconReportMoney, IconSettings2, IconUser } from "@tabler/icons-react";

export default function ConsoleSettingsIndexComponent({BearerToken}) {
  const navigate = useNavigate()
  const routeParams = useParams()
  return (
    <Tabs defaultValue="projects" variant="outline" value={routeParams.tabValue === null? "projects": routeParams.tabValue} onTabChange={(value) => navigate('/console/settings/' + value)}>
      <Tabs.List>
        <Tabs.Tab value="projects" icon={<IconSettings2 size="1rem" color="grey" />}><Text fz="sm" c="gray" fw={600}>Projects</Text></Tabs.Tab>
        <Tabs.Tab value="account" icon={<IconUser size="1rem" color="grey" />}><Text fz="sm" c="gray" fw={600}>Account</Text></Tabs.Tab>
        <Tabs.Tab value="billing" icon={<IconReportMoney size="1rem" color="grey" />}><Text fz="sm" c="gray" fw={600}>Billing</Text></Tabs.Tab>
      </Tabs.List>
      <Space h="xl" />
      <Tabs.Panel value="projects">
      <ConsoleProjectsIndexComponent
        BearerToken={window.localStorage.getItem('BearerToken')}
      />
        </Tabs.Panel>
      <Tabs.Panel value="account">
        <ConsoleAccountSettingsComponent
          BearerToken={BearerToken}
        />
      </Tabs.Panel>
      <Tabs.Panel value="billing"><Text fz="sm" c="gray" fw={400}>QueryMyDoc is in beta stage. Once beta stage is completed billing details will be added.</Text></Tabs.Panel>
    </Tabs>
  )
}