import { Button, Container, Group, LoadingOverlay, Modal, Paper, PasswordInput, Space, Text, TextInput } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useRef, useState } from "react";
import { CommonAlertsShow } from "../Common/Alerts";
import { CheckPassword, GetAccountInfoApi, UpdateAccountInfoApi, VerifyRequestApi } from "../../lib/Apis/Accounts";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

export default function ConsoleAccountSettingsComponent({ BearerToken }) {
  const [loadingOverlay, setLoadingOverlay] = useState(true)
  const [verified, setVerified] = useState(true)
  const password = useRef('')
  const newPassword = useRef('')
  const [passwordRequestEventType, setPasswordRequestEventType] = useState('')
  const [passwordModal, passwordModalHandlers] = useDisclosure(false)
  const navigate = useNavigate()
  const form = useForm({
    initialValues: {
      name: '',
      email: '',
    },

    validate: {
      name: (value) => (value === null ? 'Name cannot be empty' : value.length < 2 ? 'Name must have at least 2 letters' : null),
      email: (value) => (value === null ? 'Email cannot be empty' : /^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });

  useEffect(() => {
    try {
      setLoadingOverlay(true)
      const response = GetAccountInfoApi({ BearerToken: BearerToken })
      response.then((result) => {
        if (result.status === 200) {
          form.setValues({ name: result.json.name, email: result.json.email })
          setVerified(result.json.verified)
        } else if (result.status === 500) {
          console.log('ConsoleAccountSettingsComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('ConsoleAccountSettingsComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      console.log('ConsoleAccountSettingsComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }, [])

  function handleSubmit() {
    password.current = ''
    newPassword.current = ''
    setPasswordRequestEventType('InfoChange')
    passwordModalHandlers.open()
  }

  function handlePassword() {
    password.current = ''
    newPassword.current = ''
    setPasswordRequestEventType('ChangePassword')
    passwordModalHandlers.open()
  }

  function handleVerifyEmail() {
    if (verified === true) {
      CommonAlertsShow({ Type: 'Error', Message: 'Email Already Verified', Title: 'Error' })
      return
    } else {
      try {
        setLoadingOverlay(true)
        const response = VerifyRequestApi({ BearerToken: BearerToken })
        response.then((result) => {
          if (result.status === 200) {
            CommonAlertsShow({ Type: 'Success', Message: 'Verification Email Sent to ' + result.json.email, Title: 'Success' })
          } else if (result.status === 500) {
            console.log('ConsoleAccountSettingsComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
          } else {
            console.log('ConsoleAccountSettingsComponent error', result)
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
          setLoadingOverlay(false)
        })
      } catch (error) {
        console.log('ConsoleAccountSettingsComponent error', error)
        CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        setLoadingOverlay(false)
      }
    }
  }

  function handleDelete() {
    password.current = ''
    newPassword.current = ''
    setPasswordRequestEventType('DeleteAccount')
    passwordModalHandlers.open()
  }

  async function handleProceed() {
    let Data = {}
    if (password.current === null || password.current === '') {
      CommonAlertsShow({ Type: 'Error', Message: 'Password Required', Title: 'Error' })
      return
    }
    if (passwordRequestEventType === 'InfoChange') {
      Data = {
        name: form.values['name'],
        email: form.values['email'],
        password: password.current,
        type: 'info_change'
      }
    } else if (passwordRequestEventType === 'ChangePassword') {
      if (newPassword.current === null || newPassword.current === '') {
        CommonAlertsShow({ Type: 'Error', Message: 'New Password Required', Title: 'Error' })
        return
      } else if (password.current === newPassword.current) {
        CommonAlertsShow({ Type: 'Error', Message: 'New Password same as Old Password', Title: 'Error' })
        return
      } else {
        let errors = CheckPassword({ password: newPassword.current })
        if (errors.length > 0) {
          CommonAlertsShow({ Type: 'Error', Message: errors.join(', '), Title: 'Password Error' })
          return
        }
      }
      Data = {
        password: password.current,
        new_password: newPassword.current,
        type: 'password_change'
      }
    } else if (passwordRequestEventType === 'DeleteAccount') {
      Data = {
        password: password.current,
        type: 'delete_account'
      }
    }
    try {
      setLoadingOverlay(true)
      const response = UpdateAccountInfoApi({ BearerToken: BearerToken, Data: Data })
      response.then((result) => {
        if (result.status === 200) {
          if (passwordRequestEventType === 'InfoChange') {
            form.setValues({ name: result.json.name, email: result.json.email })
            passwordModalHandlers.close()
            setVerified(result.json.verified)
            CommonAlertsShow({ Type: 'Success', Message: 'Account Details Updated', Title: 'Success' })
          } else if (passwordRequestEventType === 'ChangePassword') {
            passwordModalHandlers.close()
            CommonAlertsShow({ Type: 'Success', Message: 'Account Password Updated', Title: 'Success' })
          } else if (passwordRequestEventType === 'DeleteAccount') {
            navigate('/signup')
            passwordModalHandlers.close()
            CommonAlertsShow({ Type: 'Success', Message: 'Account Deleted', Title: 'Success' })
          }
        } else if (result.status === 500) {
          console.log('ConsoleAccountSettingsComponent error', result)
          CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
        } else {
          console.log('ConsoleAccountSettingsComponent error', result)
          if (result.json.errors !== null) {
            form.setErrors(result.json.errors)
          } else {
            CommonAlertsShow({ Type: 'Error', Message: 'Processing Error: ' + result.json.error, Title: 'Error' })
          }
        }
        setLoadingOverlay(false)
      })
    } catch (error) {
      console.log('ConsoleAccountSettingsComponent error', error)
      CommonAlertsShow({ Type: 'Error', Message: 'Server Error', Title: 'Error' })
      setLoadingOverlay(false)
    }
  }

  function ShowPasswordConfirmationModal() {
    let title = 'Account Password'
    let placeholder = 'Password'
    if (passwordRequestEventType === 'ChangePassword') {
      placeholder = 'Old Password'
    }
    function GetMessage() {
      if (passwordRequestEventType === 'InfoChange') {
        return (
          <Text fw={400} fz="sm" c="grey">Your account password is required to complete this action.</Text>
        )
      } else if (passwordRequestEventType === 'ChangePassword') {
        return (
          <Text fw={400} fz="sm" c="grey">Your account password is required to complete this action.</Text>
        )
      } else if (passwordRequestEventType === 'DeleteAccount') {
        return (
          <Text fw={400} fz="sm" c="red">Deleting your account will remove all your data from QueryMyDoc and this action cannot be undone. Please proceed only if you are certain about this. Your account password is required to complete this action.</Text>
        )
      }
    }
    return (
      <Modal opened={passwordModal} onClose={passwordModalHandlers.close} size="sm" title={title} centered>
        <GetMessage />
        <Space h="xl" />
        <PasswordInput required aria-label="Password" placeholder={placeholder} onChange={(event) => { password.current = event.currentTarget.value; }} />
        {
          passwordRequestEventType === 'ChangePassword' &&
          <Space h="xl" />
        }
        {
          passwordRequestEventType === 'ChangePassword' &&
          <PasswordInput required aria-label="New Password" placeholder="New Password" onChange={(event) => { newPassword.current = event.currentTarget.value; }} />
        }
        <Group grow mt="md">
          <Button type="submit" variant="light" color={passwordRequestEventType === 'DeleteAccount' ? "red" : "blue"} onClick={() => handleProceed()}>Proceed</Button>
        </Group>
      </Modal>
    )
  }

  return (
    <Container size="sm" px="xs">
      {
        passwordModal &&
        <ShowPasswordConfirmationModal
        />
      }
      <Paper shadow="sm" radius="md" p="sm">
        <LoadingOverlay visible={loadingOverlay} overlayBlur={2} />
        <form onSubmit={form.onSubmit((values) => handleSubmit())}>
          <TextInput
            label={<Text fz="sm" c="grey">Name</Text>}
            description="Enter your name"
            placeholder="Name"
            size="xs"
            {...form.getInputProps('name')}
          />
          <Space h="xl" />
          <TextInput
            label={<Text fz="sm" c="grey">Email</Text>}
            description="Enter you email."
            placeholder="Email"
            size="xs"
            {...form.getInputProps('email')}
          />
          <Group grow mt="md">
            <Button type="submit" variant="light">Save</Button>
          </Group>
        </form>
      </Paper>
      <Group position="apart" mt="md">
        <Button variant="light" onClick={() => handlePassword()}>Change Password</Button>
        <Button variant="light" onClick={() => handleVerifyEmail()} disabled={verified}>{verified ? <Text c="blue">Email Verified</Text> : <Text c="blue">Send Verification Email</Text>}</Button>
        <Button variant="light" color="red" onClick={() => handleDelete()}>Delete Account</Button>
      </Group>
    </Container>
  )
}